import React from 'react';
import theme from './style'
import PropTypes from 'prop-types';
import {
  DatePicker as DP,
} from '@material-ui/pickers';
import CheckIcon from '@material-ui/icons/Check';
const DatePicker = (props) => {
  const classes = theme();
  const { style, value, onChange } = props;
  const [selectedDate, setSelectedDate] = React.useState(value);
  return (
    <div className={classes.datepickerContainer} style={style ? style : {}}>
      <DP
        className={classes.datepicker}
        disableToolbar
        autoOk
        format="YYYY-MM-DD"
        id="date-picker-inline"
        value={selectedDate}
        onChange={setSelectedDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <CheckIcon className='button' onClick={() => onChange(selectedDate)} />
    </div>
  );
}
DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
export default DatePicker;