import APICall, { APIDownload } from '../../../misc/APICall';
import { renewToken } from '../login';
export const SET_LOADING_SUPPLIER_INVOICES = 'SET_LOADING_SUPPLIER_INVOICES';
export const SET_ERROR_SUPPLIER_INVOICES = 'SET_ERROR_SUPPLIER_INVOICES';
export const SET_SUPPLIER_INVOICES_FILTER_LIST = 'SET_SUPPLIER_INVOICES_FILTER_LIST';
export const SET_SUPPLIER_INVOICES_FILTER = 'SET_SUPPLIER_INVOICES_FILTER';
export const CLEAR_SUPPLIER_INVOICES_FILTER = 'CLEAR_SUPPLIER_INVOICES_FILTER';
export const SET_SUPPLIER_INVOICES_LIST = 'SET_SUPPLIER_INVOICES_LIST';
export const SET_SUPPLIER_INVOICES_ERROR_TOTALS = 'SET_SUPPLIER_INVOICES_ERROR_TOTALS';
export const SET_SUPPLIER_INVOICES_DATA = 'SET_SUPPLIER_INVOICES_DATA';
export const SET_NOTES_LIST = 'SET_NOTES_LIST';
export const SET_CUSTOMERS_SEARCH = 'SET_CUSTOMERS_SEARCH';

export const setLoadingSupplierInvoices = (payload) => {
  return {
    type: SET_LOADING_SUPPLIER_INVOICES,
    payload: payload
  }
}
export const setErrorSupplierInvoices = (payload) => {
  return {
    type: SET_ERROR_SUPPLIER_INVOICES,
    payload: payload
  }
}
export const fetchFiltersSupplierInvoices = (payload, firstTime = true) => {
  let { access_token } = payload;
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoices/filters`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIER_INVOICES_FILTER_LIST,
        payload: {
          ok_status: res.data.ok_status.map(item => { return { id: item.id, text: item.name } }),
          release_status: res.data.release_status.map(item => { return { id: item.id, text: item.name } }),
          suppliers: res.data.suppliers.map(item => { return { id: item.id, text: item.suppl_name } }),
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchFiltersSupplierInvoices, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const setSupplierInvoicesFilter = (payload) => {
  return {
    type: SET_SUPPLIER_INVOICES_FILTER,
    payload: payload
  }
}
export const clearSupplierInvoicesFilter = (payload) => {
  let { access_token, refresh_token } = payload;
  return async dispatch => {
    dispatch({
      type: CLEAR_SUPPLIER_INVOICES_FILTER,
    })
    dispatch(
      fetchSupplierInvoicesList({
        access_token,
        refresh_token,
        flag: 7,
        supplierId: null,
        date_from: null,
        date_to: null,
        stel_unpaid: false,
        customer_unpaid: false,
        okStatus: null,
        releaseStatus: null,
        supplierInvoice: '',
        customerConnection: '',
        supplierOCR: '',
        page: 1,
        limit: 10,
      })
    )
  }
}
export const fetchSupplierInvoicesList = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    let {
      page, limit, access_token, supplierId, date_from,
      date_to, stel_unpaid, customer_unpaid, okStatus, releaseStatus,
      supplierInvoice, customerConnection, supplierOCR
    } = payload;
    let queryString = `?page=${page}&limit=${limit}`
    let addToQS = (field) => {
      return payload[field] && String(payload[field]).length
    }
    if (addToQS('supplierId')) queryString += `&f_supplier_id=${supplierId}`;
    if (addToQS('date_from')) queryString += `&f_from_date=${date_from}`;
    if (addToQS('date_to')) queryString += `&f_to_date=${date_to}`;
    queryString += `&f_unpaid_stel=${stel_unpaid ? '1' : '0'}`;
    queryString += `&f_unpaid_customer=${customer_unpaid ? '1' : '0'}`;
    if (addToQS('okStatus')) queryString += `&f_ok_status=${okStatus}`;
    if (addToQS('releaseStatus')) queryString += `&f_release_status=${releaseStatus}`;
    if (addToQS('supplierInvoice')) queryString += `&f_release_status=${supplierInvoice}`;
    if (addToQS('customerConnection')) queryString += `&f_customer_connection_number=${customerConnection}`;
    if (addToQS('supplierOCR')) queryString += `&f_supplier_ocr=${supplierOCR}`;
    return await APICall({
      access_token: access_token,
      url: `supplier-invoices${queryString}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIER_INVOICES_LIST,
        payload: {
          data: res.data.list,
          totalPages: res.pages,
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchSupplierInvoicesList, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const fetchSupplierInvoiceErrorTotals = (payload, firstTime = true) => {
  let { access_token } = payload;
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoices/errors`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIER_INVOICES_ERROR_TOTALS,
        payload: res.data
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchFiltersSupplierInvoices, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const fetchSupplierInvoiceByID = (payload, firstTime = true) => {
  let { access_token, id } = payload;
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice?supplier_invoice_id=${id}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIER_INVOICES_DATA,
        payload: res,
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchSupplierInvoiceByID, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const releaseSupplierInvoice = (payload, firstTime = true) => {
  let { access_token, refresh_token, id } = payload;
  var formdata = new FormData();
  formdata.append("supplier_invoice_id", id);
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice/release`,
      verb: 'PATCH',
      body: formdata,
    }).then(res => {
      dispatch(fetchSupplierInvoiceByID({
        id, access_token, refresh_token
      }))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(releaseSupplierInvoice, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const blockSupplierInvoice = (payload, firstTime = true) => {
  let { access_token, refresh_token, id } = payload;
  var formdata = new FormData();
  formdata.append("supplier_invoice_id", id);
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice/block`,
      verb: 'PATCH',
      body: formdata,
    }).then(res => {
      dispatch(fetchSupplierInvoiceByID({
        id, access_token, refresh_token
      }))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(blockSupplierInvoice, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const dismissSupplierInvoice = (payload, firstTime = true) => {
  let { access_token, refresh_token, id, reason } = payload;
  var formdata = new FormData();
  formdata.append("supplier_invoice_id", id);
  formdata.append("invoice_dismiss_reason", reason);
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice/dismiss`,
      verb: 'PATCH',
      body: formdata,
    }).then(res => {
      dispatch(fetchSupplierInvoiceByID({
        id, access_token, refresh_token
      }))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(dismissSupplierInvoice, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const deleteSupplierInvoice = (payload, firstTime = true) => {
  let { access_token, refresh_token, id } = payload;
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice&supplier_invoice_id=${id}`,
      verb: 'DELETE',
    }).then(res => {
      dispatch(fetchSupplierInvoiceByID({
        id, access_token, refresh_token
      }))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(deleteSupplierInvoice, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const fetchNotesList = (payload, firstTime = true) => {
  let { access_token, id } = payload;
  return async dispatch => {
    APICall({
      access_token: access_token,
      url: `supplier-invoices/notes?id=${id}&limit=9000&page=1`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_NOTES_LIST,
        payload: res.data
      })
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchNotesList, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const patchNote = (payload, firstTime = true) => {
  let { access_token, note_id, text } = payload;
  var formdata = new FormData();
  formdata.append("note_id", note_id);
  formdata.append("note", text);
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    APICall({
      access_token: access_token,
      url: `supplier-invoices/note`,
      verb: 'PATCH',
      body: formdata,
    }).then(res => {
      dispatch(fetchNotesList(payload))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(patchNote, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const createNote = (payload, firstTime = true) => {
  let { access_token, id, text } = payload;
  var formdata = new FormData();
  formdata.append("id", id);
  formdata.append("note", text);
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    APICall({
      access_token: access_token,
      url: `supplier-invoices/note`,
      verb: 'POST',
      body: formdata,
    }).then(res => {
      dispatch(fetchNotesList(payload))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(createNote, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}

export const fetchCustomerListForConnectionSearch = (payload, firstTime = true) => {
  let { access_token, supplier_id, search } = payload;
  return dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    return APICall({
      access_token: access_token,
      url: `supplier-invoice/connection/search?supplier_id=${supplier_id}&search=${search}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CUSTOMERS_SEARCH,
        payload: res,
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchCustomerListForConnectionSearch, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}

export const disableSupplierConnection = (payload, firstTime = true) => {
  let { access_token, customer_id, supplier_id, customer_nr_at_supplier } = payload;
  return async dispatch => {
    APICall({
      access_token: access_token,
      url: `supplier-invoice/connection?customer_id=${customer_id}&supplier_id=${supplier_id}&customer_nr_at_supplier=${customer_nr_at_supplier}`,
      verb: 'DELETE',
    }).then(res => {
      dispatch(fetchSupplierInvoiceByID(payload))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(disableSupplierConnection, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}
export const downloadSupplierInvoicePDF = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingSupplierInvoices(true))
    let { access_token, id, type ,customer_id} = payload;
    let url='';
    if(type==='pdfSupplierInvoice'){
      url=`supplier-invoices/pdf?supplier_invoice_id=${id}`;
      if(customer_id){
        url+=`&customer_id=${customer_id}`
      }
    }else{
      url=`invoice/pdf?invoice_id=${id}`
    }
    return await APIDownload({
      access_token: access_token,
      url: url,
      verb: 'get',
    }).then(res => {
      dispatch(setLoadingSupplierInvoices(false))
      return res;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(downloadSupplierInvoicePDF, payload, dispatch)
      } else {
        dispatch(setErrorSupplierInvoices(error))
      }
    })
  }
}