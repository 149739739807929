import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    controlers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fill: '#989898',
        cursor: 'pointer',
        padding:'0px 10px'
      }
    },
    container: {
      overflow: 'auto',
      height: 645,
    },

  }));
  return styles();
}
export default theme;

