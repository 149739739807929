import { store } from '../redux/store';
import branding from "../config/config.json";

const parseTranslation = translation => {
  let r = /##(.+?)##/g;
  let m;
  let out = translation;
  while(m = r.exec(translation)) {
    let k = m[1];
    if (typeof branding[k] != "undefined") {
      out = out.replace(m[0], branding[k]);
    }
  }
  return out;
}

const translate = (area, key) => {
  const state = store.getState();
  let translations = state.settings.translations;
  try {
    let translation = translations[`${branding.namespace}${area.length > 0 ? '/' + area : ''}`].find(item => item.hash === key).text;
    return parseTranslation(translation);
  } catch (error) {
    return area + ' - ' + key;
  }
}
export default translate;
