

import React from "react";
import colorConfig from "../../template/settings";

const SVG = ({
  fill = colorConfig.color1,
}) => {
  return (
    <svg width="45" height="45" xmlns="http://www.w3.org/2000/svg">
      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="47" width="47" y="-1" x="-1" />
      </g>
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="svg_1">
          <path fill={fill} stroke="null" id="svg_2" d="m27.11399,10.17608c2.0621,1.29292 3.50808,3.48081 3.77134,6.02045c0.84098,0.39294 1.77436,0.61891 2.76394,0.61891c3.61272,0 6.54085,-2.92813 6.54085,-6.54029c0,-3.61272 -2.92813,-6.54085 -6.54085,-6.54085c-3.57821,0.00111 -6.48074,2.87693 -6.53528,6.44178zm-4.28506,13.39226c3.61272,0 6.54085,-2.92869 6.54085,-6.54085s-2.92869,-6.54029 -6.54085,-6.54029c-3.61216,0 -6.54196,2.92869 -6.54196,6.54085s2.9298,6.54029 6.54196,6.54029zm2.77452,0.44582l-5.55015,0c-4.61789,0 -8.37476,3.75742 -8.37476,8.37531l0,6.78741l0.01725,0.10631l0.46752,0.14638c4.40695,1.37696 8.23561,1.83613 11.38693,1.83613c6.15514,0 9.72278,-1.75487 9.94262,-1.86675l0.43691,-0.22096l0.04675,0l0,-6.78852c0.00167,-4.61789 -3.7552,-8.37531 -8.37309,-8.37531zm10.82145,-6.75234l-5.50729,0c-0.05955,2.20347 -1.00016,4.18766 -2.48788,5.61471c4.10473,1.22056 7.10799,5.02697 7.10799,9.52297l0,2.0916c5.43772,-0.19925 8.57123,-1.7404 8.77771,-1.84393l0.43691,-0.22152l0.04675,0l0,-6.78964c0,-4.61733 -3.75687,-8.3742 -8.3742,-8.3742zm-25.07306,-0.44526c1.27956,0 2.47007,-0.37346 3.47858,-1.00962c0.32059,-2.09105 1.44152,-3.91827 3.04279,-5.16277c0.00668,-0.12245 0.01837,-0.24378 0.01837,-0.36734c0,-3.61272 -2.92869,-6.54085 -6.53973,-6.54085c-3.61327,0 -6.54085,2.92813 -6.54085,6.54085c0,3.61105 2.92757,6.53973 6.54085,6.53973zm5.87407,6.05997c-1.48048,-1.41982 -2.41775,-3.39343 -2.48621,-5.58354c-0.20426,-0.01503 -0.4063,-0.03117 -0.61446,-0.03117l-5.54959,0c-4.61789,0 -8.37476,3.75687 -8.37476,8.3742l0,6.78852l0.01725,0.10464l0.46752,0.14749c3.53535,1.10368 6.69057,1.61239 9.43113,1.77269l0,-2.04986c0.00111,-4.496 3.00327,-8.30129 7.10911,-9.52297z" />
        </g>
        <g id="svg_3" />
        <g id="svg_4" />
        <g id="svg_5" />
        <g id="svg_6" />
        <g id="svg_7" />
        <g id="svg_8" />
        <g id="svg_9" />
        <g id="svg_10" />
        <g id="svg_11" />
        <g id="svg_12" />
        <g id="svg_13" />
        <g id="svg_14" />
        <g id="svg_15" />
        <g id="svg_16" />
        <g id="svg_17" />
      </g>
    </svg>
  );
};

export default SVG;
