const custStatus = [
  {
    id:'active',
    text:'Active',
  },
  {
    id:'blocked',
    text:'Blocked',
  },
  {
    id:'closed',
    text:'Closed',
  },
]


export default custStatus;
