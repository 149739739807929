import branding from "../config/config.json";

const settings = {
  color1: branding.color1,
  color2: branding.color2,
  color3: branding.color3,
  color4: branding.color4,
  color5: branding.color5,
  color6: branding.color6,
  logoBg: branding.logoBg,
  rgba: branding.rgba,
  logoAuthPath: branding.logoAuthPath,
  logoSmallPath: branding.logoSmallPath,
  logoPath: branding.logoPath,
}

/*const stelSettings = {
  color1: '#0C1B4D',
  color2: '#0C1B4D',
  color3: '#0C1B4D',
  color4: '#FED010',
  color5: '#fff',
  color6: '#fff',
  logoBg:'#0C1B4D',
  rgba: 'rgba(12, 27, 77,0.2)',
  logoAuthPath: '/stelLogos/logoAuth.png',
  logoSmallPath: '/stelLogos/logo_small.png',
  logoPath: '/stelLogos/logo.png',
}
const cheapEnergySettings = {
  color1: '#000000',
  color2: '#000000',
  color3: '#000000',
  color4: '#ffea00',
  color5: '#fff',
  color6: '#fff',
  logoBg:'#ffea00',

  rgba: 'rgba(0, 0,0,0.2)',
  logoAuthPath: '/cheapEnergyLogos/logoAuth.png',
  logoSmallPath: '/cheapEnergyLogos/logo_small.png',
  logoPath: '/cheapEnergyLogos/logo.png',
}*/

export default settings;