export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const setSelectedMenu = (payload) => {
  return {
    type: SET_SELECTED_MENU,
    payload: payload
  }
}
export const setBreadcrumbs = (payload) => {
  return {
    type: SET_BREADCRUMBS,
    payload: payload
  }
}
