import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    saveButton: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-end',
      // marginLeft: -10,
      // width: 'calc(100% + 20px)',
      '& button': {
        maxWidth: 150,
      }
    },
    title: {
      padding: '10px',
      color:'#989898',
      fontSize:20,
      fontWeight:400,
    }
  }));
  return styles();
}
export default theme;

