import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
const HomeCart = (props) => {
  const classes = theme();
  const {
    onClick, color, count, icon, title, text,
  } = props;

  let Icon = icon;
  return (
    <div className={`${classes.container}`}>
      <div className='count'>{count}</div>
      <div className='icon'>
        <Icon />
      </div>
      <div className='title'>{title}</div>
      <div className='text'>{text || ''}</div>
      <div className='action' onClick={onClick} ><PlayCircleOutlineIcon /></div>
    </div>
  );
}
HomeCart.propTypes = {
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  color: PropTypes.string.isRequired,
}

export default memo(HomeCart);