import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    paperPadding: {
      marginTop: 15,
      padding: 15,
    },

  }));
  return styles();
}
export default theme;

