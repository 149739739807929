import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    chip: {
      padding: '4px 15px',
      fontSize: 11,
      fontWeight: 200,
      borderRadius: 5,
      textTransform: 'uppercase',
      color: 'white',
    },
    green: {
      backgroundColor: '#5ba26f'
    },
    red: {
      backgroundColor: '#ff524d'
    },
    grey: {
      backgroundColor: '#A0A0A0'
    },
    blue: {
      backgroundColor: settings.color2
    },
    border_blue: {
      border: `2px solid ${settings.color3}`,
      color: settings.color2,
      cursor: 'pointer',
    },
  }));
  return styles();
}
export default theme;

