import {
  SET_DASH_DATA,
} from '../actions/home';

const initialState = {
  num_customers: 0,
  sum_connected_customers: 0,
}
const CustomerReducer = function (state = initialState, action) {
  const { type, payload, } = action;

  switch (type) {
    case SET_DASH_DATA:
      return {
        ...state,
        ...payload,
      }
    default:
      return state;
  }
}
export default CustomerReducer;