import React, { Fragment } from 'react';
import theme from './style'
import { Typography, } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import translate from '../../../misc/applyTranslation';
const CustomerConnectionSearch = (props) => {
  const classes = theme();
  const {
    id,
    name,
    props_cust_number,
    address1,
    post_code,
    city,
    connected,
    onClick,
    showOnly
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(id)
    }
  }
  return (
    <div className={clsx(classes.cartWrap, showOnly ? classes.selectedWrap : '')} onClick={handleClick}>
      <div className={'namingGroup'}>
        <div className={'topWrap'}>
          <div className={'name'}>{name}</div>
          <div className={'number'}>{props_cust_number}</div>
        </div>

        <div className={'bottomWrap'}>
          <div className={'address'}>{address1}</div>
          <div className={'address'}>{post_code} {city}</div>
        </div>
      </div>
      {!showOnly &&
        <div className={clsx('boxWrap', connected ? 'boxWrap_connected' : 'boxWrap_not_connected')}>
          <div className={clsx('status', connected ? 'status_connected' : 'status_not_connected')}>
            {translate('supplier-invoices/detail', 'customer_connect_action_connect')}
          </div>
          <div className={'text'}>{
            connected ?
              translate('supplier-invoices/detail', 'customer_connect_have_connection') :
              translate('supplier-invoices/detail', 'customer_connect_no_connection')}</div>
        </div>
      }
    </div>
  );
}
CustomerConnectionSearch.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}
export default CustomerConnectionSearch;