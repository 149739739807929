import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    backContainer: {
      margin: '0px -10px',
      width: 'calc(100% + 20px)',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 20,
      '& button': {
        maxWidth: 140,
      }
    },
    clientBox: {
      padding: 10,
      marginBottom: 20,
      color: '#989898',
      fontWeight: 200,
      fontSize: 13,
      '& .title': {
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 10,
      },
      '& .table': {
        margin: '10px 0px',
      },
      '& .buttonGroup': {
        margin: '0px -10px',
        width: 'calc(100% + 20px)',
      }

    },
    invoiceBox: {
      padding: 10,
      color: '#989898',
      fontWeight: 200,
      fontSize: 13,
      '& .title': {
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 10,
      }
    },
    dialogContent: {
      paddingBottom: 20
    }
  }));
  return styles();
}
export default theme;

