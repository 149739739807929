import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import { useHistory } from 'react-router-dom';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import moment from 'moment'
import translate from '../../../misc/applyTranslation';
import toMoney from '../../../misc/serializeToMoney';
const OutboundInvoice = (props) => {
  const classes = theme();
  const history = useHistory();
  const {
    data,
    downloadPDF
  } = props;
  const handleClick = (id, type) => {
    if (type === 'line') {
      history.push(`supplier-invoices/${id}`)
    }
  }
  const handlePDFClick = (e, id, type) => {
    e.stopPropagation()
    if (downloadPDF) {
      downloadPDF(id, type)
    }
  }
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.container} >
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead className='specialHeader'>
            <TableRow>
              <TableCell colSpan={7}>{translate('supplier-invoices', 'table_tabs_supplier_invoices')}</TableCell>
              <TableCell colSpan={3}>{translate('supplier-invoices', 'table_tabs_customer_connection')}</TableCell>
              <TableCell colSpan={6}>{translate('supplier-invoices', 'table_tabs_stel_invoice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>{translate('supplier-invoices', 'table_header_view_icon')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_supplier_invoice')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_supplier')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_amount')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_supplier_invoice_invoice_due')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_payed')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_supplier_invoice_view_pdf')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_customer')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_name')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_okstatus')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_invoice')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_amount')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_stel_invoice_invoice_due')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_stel_invoice_payed')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_relase')}</TableCell>
              <TableCell>{translate('supplier-invoices', 'table_header_stel_invoice_view_pdf')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, ind) => (
              <TableRow key={ind} className={classes.cursor} onClick={() => handleClick(row.supplier_invoice_id, 'line')}>
                <TableCell align='center'><PlayCircleOutlineIcon /></TableCell>
                <TableCell>{row.supplier_invoice_id}</TableCell>
                <TableCell>{row.suppl_name}</TableCell>
                <TableCell>{toMoney(row.supplier_invoice_amount)}</TableCell>
                <TableCell>{row.supplier_invoice_due ? moment.unix(row.supplier_invoice_due).format('YYYY-MM-DD') : ''} </TableCell>
                <TableCell>{row.supplier_invoice_payed ? moment.unix(row.supplier_invoice_payed).format('YYYY-MM-DD') : ''}</TableCell>
                <TableCell align='center'><img src='/pdf-file.png' alt='PDF' onClick={(e) => handlePDFClick(e, row.supplier_invoice_id, 'pdfSupplierInvoice')} /></TableCell>

                <TableCell style={{ textDecoration: row.supplier_connection_disable_date ? 'line-through' : 'unset' }}>{row.cust_number}</TableCell>
                <TableCell style={{ textDecoration: row.supplier_connection_disable_date ? 'line-through' : 'unset' }}>{row.customer_name1}</TableCell>
                <TableCell><StatusChips type={row.ok_color} label={row.ok_name} /> </TableCell>
                <TableCell>{row.invoice_stel_id}</TableCell>
                <TableCell>{row.invoice_stel_amount ? toMoney(row.invoice_stel_amount) : ''}</TableCell>
                <TableCell>{row.invoice_stel_due ? moment.unix(row.invoice_stel_due).format('YYYY-MM-DD') : ''}</TableCell>
                <TableCell>{row.invoice_stel_payed ? moment.unix(row.invoice_stel_payed).format('YYYY-MM-DD') : ''}</TableCell>
                <TableCell><StatusChips type={row.release_color} label={row.release_name} /> </TableCell>
                <TableCell align='center'>{row.invoice_stel_id && <img src='/pdf-file.png' alt='PDF' onClick={(e) => handlePDFClick(e, row.invoice_stel_id, 'pdfInvoice')} />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment >
  );
}
OutboundInvoice.propTypes = {
  data: PropTypes.array.isRequired,
}

export default memo(OutboundInvoice);