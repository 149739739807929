import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    fixInputMargins:{
      margin:'0px -10px 15px -10px'
    }
  }));
  return styles();
}
export default theme;

