import React from 'react';
import theme from './style'
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const Label = (props) => {
  const classes = theme();
  const { text, } = props;
  return (
    <Typography
      className={classes.label}
    >{text}</Typography>
  );
}
Label.propTypes = {
  text: PropTypes.string.isRequired,
}
export default Label;