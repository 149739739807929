import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './routes';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { PersistGate } from 'redux-persist/integration/react'

import "moment/locale/en-gb";
import "moment/locale/sv";


let locale = window.location.pathname.match(/.{3}/g) === '/se' ? 'sv' : 'en-gb'
ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
);
