import React from 'react';
import theme from './style'
import {FormControl, Select, MenuItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import translate from '../../../misc/applyTranslation'

const BorderSelect = (props) => {
    const classes = theme();
    const {items, selected, onChange, invalid, allowAll, placeholder, placeholderNS} = props;

    const getItemText = item => {
        if(item.text) {
            return item.text.replace("Year", translate("", "year_text"))
        } else if (item.delivery) {
            return item.delivery
        } else {
            return ""
        }
    };

    const getItemId = item => {
        return item.id ? item.id : item.delivery_id;
    };

    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.value)
        }
    }
    return (
        <FormControl variant="outlined" className={`${classes.selectGroup} ${invalid ? classes.invalid : ''}`}>
            <Select
                className='select'
                value={!selected ? 'null' : selected}
                onChange={handleChange}
                // placeholder={placeholder}
            >
                {allowAll &&
                    <MenuItem value={'null'}>{translate('', 'generic_select_all')}</MenuItem>
                }
                {!allowAll &&
                    <MenuItem style={{display: 'none'}}
                              value={'null'}>{translate(placeholderNS || '', placeholder || 'generic_select')}</MenuItem>
                }
                {items && items.map(item =>
                    <MenuItem key={item.id} disabled={item.disabled}
                              value={String(getItemId(item))}>{getItemText(item)}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
BorderSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    selected: PropTypes.string,
}
export default BorderSelect;
