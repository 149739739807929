import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
    },
    table: {
      minWidth: 650,
      width: 'calc(100% - 50px)',
      margin: '15px 25px',
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 200,
        '& svg':{
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
    },
    importListItem: {
      color:`${settings.color2} !important`,
      fontWeight: '400 !important',
      opacity: '0.8',
    }
  }));
  return styles();
}
export default theme;

