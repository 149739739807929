import {
  SET_CUSTOMER_TAGS,
  SET_TRANSLATIONS,
  SET_DELIVERY_TYPES
} from '../actions/settings/index';

import MockDataAutoGiro from '../../misc/mockDataAutoGiro';
import MockDataCustomerStatus from '../../misc/mockDataCustStatus';
import MockInvoicingPropsSuppl from '../../misc/mockInvoicingPropsSuppl';
const initialState = {
  delivery_types: [],
  auto_giro_types: MockDataAutoGiro,
  customer_status: MockDataCustomerStatus,
  props_suppl: MockInvoicingPropsSuppl,
  tags: [],
  translations: null,
  client: 'cheapEnergy',
}
const SettingsReducer = function (state = initialState, action) {
  const { type, payload, } = action;

  switch (type) {
    case SET_CUSTOMER_TAGS:
      return {
        ...state,
        tags: payload,
      }
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload,
      }
    case SET_DELIVERY_TYPES:
      return {
        ...state,
        delivery_types: payload
      }
    default:
      return state;
  }
}
export default SettingsReducer;
