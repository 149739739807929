
import React, { memo, useEffect, useState, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BorderButton, } from '../../components/buttons'
import { BorderSelect, } from '../../components/inputs'
import { Paper } from '@material-ui/core';
import translate from '../../misc/applyTranslation';
import {fetchFilesList, fetchFileData, uploadFile, setSelectedXML} from '../../redux/actions/files';
import { Markup } from 'interweave';
const FileView = (props) => {
  const classes = theme();
  const [file, setFile] = useState()
  const {
    fetchFilesList,
    fetchFileData,
    uploadFile,
    access_token,
    refresh_token,
    fileList,
    fileData,
    selectedXML,
    setSelectedXML
  } = props;
  const handleFileUpload = () => {
    if (file) {
      var formdata = new FormData();
      formdata.append("file", file, file.name);
      uploadFile({
        access_token,
        refresh_token,
        formdata,
      })
      document.getElementById("filehandler").value = "";
    }
  }
  useEffect(() => {
    fetchFilesList({
      access_token,
      refresh_token,
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (selectedXML) {
      fetchFileData({
        access_token,
        refresh_token,
        id: selectedXML
      })
    }
    // eslint-disable-next-line
  }, [selectedXML]);
  const formatXML = () => {
    let data = JSON.parse(JSON.stringify(fileData))
    data = data.replace(/\|S\|(.+)/g, '<p class="upload_log_success">$1</p>')
    data = data.replace(/\|E\|(.+)/g, '<p class="upload_log_error">$1</p>')
    data = data.replace(/\|L\|(.+)/g, '<p class="upload_log_log">$1</p>')
    data = data.replace(/\|SUMMARY\|([\s\S]+)/, '<pre class="upload_log_summary">$1</pre>')
    return data;
  }
  return (
    <React.Fragment>
      <Paper className={classes.fileInputContainer}>
        <div className='title'>{translate('files', 'file_upload_panel_title')}</div>
        <div className='text'>{translate('files', 'file_upload_panel_text')}</div>
        <input type='file' id='filehandler' className='fileInput' onChange={(e) => setFile(e.target.files[0])} />
        <div className='buttonContainer'>
          <BorderButton onClick={handleFileUpload} text={translate('files', 'file_upload_panel_title')} />
        </div>
      </Paper>
      <Paper className={classes.fileListContainer}>
        <div className='title'>{translate('files', 'file_report_panel_title')}</div>
        <div className='selectContainer'>
          <BorderSelect
            selected={selectedXML}
            items={fileList}
            onChange={(e) => { setSelectedXML(e) }}
          />
        </div>
        {fileData &&
          <div className={classes.fileDetailContainer}>
            <Markup content={formatXML(fileData)} />
          </div>
        }
      </Paper>
    </React.Fragment>
  )
}
FileView.propTypes = {
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  fileList: store.files.fileList,
  fileData: store.files.fileData,
  selectedXML: store.files.selectedXML

});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchFilesList,
    fetchFileData,
    uploadFile,
    setSelectedXML
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(FileView));
