import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonGroup: {
      width: '100%',
      '& .MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child)': {
        marginLeft: 0,
      }
    },
    button25: {
      width: '25%',

    },
    button20: {
      width: '20%',
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
      background: settings.color5,
      color: settings.color1,
      "-webkit-box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      "-moz-box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)",
      padding: "6px 35px",
      border: 'none',
      '&:hover': {
        background: settings.rgba,
        color: settings.color2,
      },
    },
    selected: {
      color: settings.color6,
      background: settings.color3,
      '&:hover': {
        color: settings.color6,
        background: settings.color3,
      }
    }

  }));
  return styles();
}
export default theme;

