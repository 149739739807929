import APICall from '../../../misc/APICall';
import { renewToken } from '../login'
export const SET_LOADING_FILES = 'SET_LOADING_FILES';
export const SET_ERROR_FILES = 'SET_ERROR_FILES';
export const SET_FILE_LIST = 'SET_FILE_LIST';
export const SET_FILE_DATA = 'SET_FILE_DATA';
export const SET_SELECTED_XML = "SET_SELECTED_XML";

export const setLoadingFiles = (payload) => {
  return {
    type: SET_LOADING_FILES,
    payload: payload
  }
}
export const setErrorFiles = (payload) => {
  return {
    type: SET_ERROR_FILES,
    payload: payload
  }
}
export const setSelectedXML = payload => {
  return {
    type: SET_SELECTED_XML,
    payload
  }
}
export const fetchFilesList = (payload, firstTime = true) => {
  let { access_token } = payload;
  return async dispatch => {
    dispatch(setLoadingFiles(true))
    return await APICall({
      access_token: access_token,
      url: `file/logs`,
      verb: 'get',
    }).then(res => {
      let keys = Object.keys(res);
      dispatch({
        type: SET_FILE_LIST,
        payload: keys.map(_key => {
          return {
            id: res[_key].key,
            text: res[_key].value,
          }
        })
      })
      // Added by Maximilian, it is used to select the file that was uploaded with uploadFile
      if(firstTime == false){
        let lastItem = Object.keys(res).pop();
        let data = {access_token:payload['access_token'], id:lastItem}
        dispatch(fetchFileData(data))
        dispatch(setSelectedXML(data.id));
      }
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchFilesList, payload, dispatch)
      } else {
        dispatch(setErrorFiles(error))
      }
    })
  }
}
export const fetchFileData = (payload, firstTime = true) => {
  let { access_token, id } = payload;
  return async dispatch => {
    dispatch(setLoadingFiles(true))
    return await APICall({
      access_token: access_token,
      url: `file/log?log_id=${id}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_FILE_DATA,
        payload: res
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchFileData, payload, dispatch)
      } else {
        dispatch(setErrorFiles(error))
      }
    })
  }
}
export const uploadFile = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingFiles(true))
    let { access_token, formdata } = payload;
    return await APICall({
      access_token: access_token,
      url: `file/upload`,
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch(fetchFilesList(payload, false))
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(uploadFile, payload, dispatch)
      } else {
        dispatch(setErrorFiles(error))
      }
    })
  }
}
