import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      padding: 20,
      '& .titleHome': {
        color: settings.color1,
        fontWeight: 'bold',
        fontSize: 30,
        opacity: 0.8,
        marginBottom: 10,
      }
    }
  }));
  return styles();
}
export default theme;

