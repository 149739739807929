import React from 'react';
import theme from './style'
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';


const TextArea = (props) => {
  const classes = theme();
  const { placeholder, value, onChange, disabled, rows } = props;
  return (
    <TextField
      multiline
      rows={rows || 4}
      disabled={disabled}
      onChange={(e) => { if (onChange) onChange(e) }}
      className={classes.textinput}
      placeholder={placeholder}
      value={value}
    />
  );
}
TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
}
export default TextArea;