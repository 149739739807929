import {
  SET_LOADING_SUPPLIER_INVOICES,
  SET_ERROR_SUPPLIER_INVOICES,
  SET_SUPPLIER_INVOICES_FILTER_LIST,
  SET_SUPPLIER_INVOICES_FILTER,
  CLEAR_SUPPLIER_INVOICES_FILTER,
  SET_SUPPLIER_INVOICES_LIST,
  SET_SUPPLIER_INVOICES_ERROR_TOTALS,
  SET_SUPPLIER_INVOICES_DATA,
  SET_NOTES_LIST,
  SET_CUSTOMERS_SEARCH,
} from '../actions/supplierInvoices';

const initialState = {
  loading: false,
  error: false,
  search_ok_status_list: [],
  search_release_status_list: [],
  search_suppliers_list: [],
  ok_errors: 0,
  relese_error: 0,
  supplierInvoicesFilter: {
    flag: 7,
    supplierId: null,
    date_from: null,
    date_to: null,
    stel_unpaid: false,
    customer_unpaid: false,
    okStatus: 'not-ok-only',
    releaseStatus: null,
    supplierInvoice: '',
    customerConnection: '',
    supplierOCR: '',
    page: 1,
    limit: 10,
  },
  supplierInvoicesList: {
    data: [],
    totalPages: 0
  },
  supplierInvoiceDetail: {
    batch_data: [],
    supplier_connection_data: [],
    supplier_data: [],
    facilities: [],
    customer_data: [],
    supplier_invice_data: [],
  },
  notesList: [],
  customersSearchList: [],
}
const SettingsReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_LOADING_SUPPLIER_INVOICES:
      return {
        ...state,
        loading: payload,
      }
    case SET_ERROR_SUPPLIER_INVOICES:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_SUPPLIER_INVOICES_FILTER_LIST:
      return {
        ...state,
        loading: false,
        search_ok_status_list: payload.ok_status,
        search_release_status_list: payload.release_status,
        search_suppliers_list: payload.suppliers,
      }
    case SET_SUPPLIER_INVOICES_FILTER:
      let tempCLF = JSON.parse(JSON.stringify(state.supplierInvoicesFilter));
      tempCLF[payload.key] = payload.value;
      return {
        ...state,
        supplierInvoicesFilter: tempCLF,
      }
    case CLEAR_SUPPLIER_INVOICES_FILTER:
      return {
        ...state,
        supplierInvoicesFilter: {
          flag: 7,
          supplierId: null,
          date_from: null,
          date_to: null,
          stel_unpaid: false,
          customer_unpaid: false,
          okStatus: null,
          releaseStatus: null,
          supplierInvoice: '',
          customerConnection: '',
          supplierOCR: '',
          page: 1,
          limit: 10,
        },
      }
    case SET_SUPPLIER_INVOICES_LIST:
      return {
        ...state,
        loading: false,
        supplierInvoicesList: payload,
      }
    case SET_SUPPLIER_INVOICES_ERROR_TOTALS:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case SET_SUPPLIER_INVOICES_DATA:
      return {
        ...state,
        supplierInvoiceDetail: payload,
        loading: false,
      }
    case SET_NOTES_LIST:
      return {
        ...state,
        loading: false,
        notesList: payload,
      }
    case SET_CUSTOMERS_SEARCH:
      return {
        ...state,
        customersSearchList: payload,
        loading: false,

      }

    default:
      return state;
  }
}
export default SettingsReducer;