import React, { Fragment, memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
const SupplierDetails = (props) => {
  const classes = theme();
  const {
    supplier_data,
  } = props;

  return (
    <Fragment>
      <div className={classes.titleGray}>{translate('supplier-invoices/detail', 'add_supplier_panel_supplier_label')}</div>
      <div className={classes.text}>{translate('supplier-invoices/detail', 'customer_panel_supplier_id_label')} {supplier_data.id}</div>
      <div className={classes.text}>{supplier_data.suppl_name}</div>
    </Fragment >
  );
}
SupplierDetails.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  supplier_data: store.supplierInvoices.supplierInvoiceDetail.supplier_data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(SupplierDetails));