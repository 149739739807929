import React, {memo, useEffect,} from 'react';
import theme from './style';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {BorderButton,} from '../../components/buttons'
import {TextInput,} from '../../components/inputs'
import {Grid, Paper} from '@material-ui/core';
import {useHistory, useParams, withRouter} from "react-router-dom";
import Label from '../../components/label'
import {setFormData, initiateBankAuth, stopBankIdAuthProccess, initiateHostedBankAuth} from '../../redux/actions/login';
import translate from '../../misc/applyTranslation'
import settings from '../../template/settings'
import APICall from "../../misc/APICall";
import branding from "../../config/config.json";

const AuthBankID = (props) => {
  const classes = theme();
  const history = useHistory();
  const params = useParams();
  const { lang } = params
  const {setFormData, initiateBankAuth, ssn, bankAuthInit, stopBankIdAuthProccess, abortedBankIdProccess} = props;

  const handleForm = (key, value) => {
    setFormData({key: key, value: value})
  }

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
    } catch (error) {
    }
    initiateBankAuth({
      ssn
    })
  }
  const handleCancel = (e) => {
    try {
      e.preventDefault();
    } catch (error) {
    }
    stopBankIdAuthProccess()
  }

  const initiateHostedBankAuth = () => {
    let formData = new FormData();
    formData.append("lang", lang);
    APICall({
      url: 'begin_hosted_login_procedure',
      verb: "post",
      body: formData
    })
      .then(res => {
        if (res) {
          window.location = res.redirectUrl
        }
      })
  };

  useEffect(() => {

    if (branding.bankid_use_hosted_login === "true" && history.location.pathname === "/"+lang) {
      //initiateHostedBankAuth();
    }
  }, []);

  let notRunningProcess = !bankAuthInit || (bankAuthInit && abortedBankIdProccess);
  return (
    <form onSubmit={(e) => e.preventDefault()} className={classes.formContainer}>
      <Grid container className={'gridContainer'}>
        <React.Fragment>
          <Grid item xs={11} sm={9} md={7} lg={5} className={'gridItemContainer'}>

            {notRunningProcess &&
            <div className={'inputGroup'}>
              <BorderButton type='submit' text={translate('login', 'button_login_bankid')} onClick={initiateHostedBankAuth}/>
              <Label text={translate('', 'login_text')}/>
            </div>
            }
            {!notRunningProcess &&
            <div className={'inputGroup'} style={{textAlign: 'center'}}>
              <Label text={translate('login', 'label_bankid_auth_proccess_running')}/>
              <BorderButton type='submit' text={translate('', 'button_cancel')} onClick={handleCancel}/>
            </div>
            }

          </Grid>

        </React.Fragment>
      </Grid>
    </form>
  )
}
AuthBankID.propTypes = {
  setFormData: PropTypes.func.isRequired,
  initiateBankAuth: PropTypes.func.isRequired,
  ssn: PropTypes.string.isRequired,
  bankAuthInit: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  ssn: store.login.ssn,
  bankAuthInit: store.login.bankAuthInit,
  abortedBankIdProccess: store.login.abortedBankIdProccess

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setFormData,
    initiateBankAuth,
    stopBankIdAuthProccess
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthBankID)));
