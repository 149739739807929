import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    titleGray: {
      fontSize: 20,
      textTransform: 'uppercase',
      fontWeight: 'normal',
      marginBottom: 10,
      '& span': {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'capitalize',
      }
    },
    text: {
      fontSize: 13,
      fontWeight: 400,
    },
    bold: {
      fontWeight: 'bold'
    },
    columnContainer: {
      display: 'flex',
      '& .columnlabel': {
        flexGrow: 1,
      },
      '& .columnvalue': {
        flexGrow: 1,
      }
    },
    fixMarginConnection: {
      marginTop: 10,
      width: 'calc(100% + 20px)',
      marginLeft: -10,
      '& p': {
        textAlign: 'center',
        fontSize: 13,
        fontWeight: 400,
      },
      '& .confirmArea': {
        display: 'flex',
        justifyContent: 'space-around',
        '& button': {
          maxWidth: 120,
        }
      }
    },
  }));
  return styles();
}
export default theme;

