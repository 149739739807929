
import React, { memo, } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import translate from '../../misc/applyTranslation';
import branding from '../../config/config.json';
const ContactsView = (props) => {
  const classes = theme();
  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <div className='title'>{translate('contacts', 'contacts_panel_title')}</div>
        <div className='text'>{translate('contacts', 'contacts_panel_mail_label')}: <span>{branding.email}</span></div>
        <div className='text'>{translate('contacts', 'contacts_panel_call_label')}: <span>{branding.phone}</span></div>
      </Paper>
    </React.Fragment>
  )
}

export default memo(ContactsView);
