import React, { useState, memo } from 'react';
import { TextField, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import theme from './style';
import clsx from 'clsx';
import translate from '../../../misc/applyTranslation'
const AutoCompleteComponent = (props) => {
  const { items, selected, onChange, invalid } = props;
  const [inputValue, setInputValue] = useState('');
  const classes = theme();

  return (
    <FormControl variant="outlined" className={clsx(classes.selectGroup, invalid ? classes.invalid : '')}>
      <Autocomplete
        className='select'
        value={selected ? items.find(item => String(item.id) === String(selected)) : undefined}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue ? newValue : null);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(option) => {
          return (
            <div className={classes.selectOption}>
              {/* fixme translate */}
              <span style={{ color: '#0C1B4D', textTransform: 'uppercase' }}>{option.suppl_name} </span>
              (Org.Number: { option.suppl_org} PG / BG Account: { option.suppl_bg})
            </div>
          )
        }}
        getOptionLabel={(option) => option.text}
        options={items}
        renderInput={(params) => <TextField placeholder={translate('', 'generic_select')} {...params} />}
      />
    </FormControl>
  );
}
AutoCompleteComponent.propTypes = {
  items: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
export default memo(AutoCompleteComponent);