import React, {memo, useEffect} from "react";
import {connect} from 'react-redux';
import {withRouter, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {checkBankAuth} from "../../redux/actions/login";

const AuthBankIdHosted = props => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const grandidsession = params.get('grandidsession');
  const {checkBankAuth} = props;

  useEffect(() => {
    if (grandidsession)
      checkBankAuth({
        sessionId: grandidsession
      })
  }, []);

  return (
    <div></div>
  );
};

AuthBankIdHosted.propTypes = {
  checkBankAuth: PropTypes.func.isRequired
}
const mapStateToProps = store => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    checkBankAuth
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(AuthBankIdHosted)));
