import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 240;

const theme = (props) => {
  const styles = makeStyles(theme => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      marginTop: 60,
      overflow: 'auto',
      '& .MuiTypography-body1':{
        fontSize:'13px !important',
        fontWeight:'200 !important',
        textTransform:'uppercase',
        lineHeight:'24px'
      },
      '& svg': {
        fill: '#919191',
        fontSize: 19
      },
      '& img': {
        maxHeight: 19,
        maxWidth: 19,
      },
      '& ul': {
        padding: 0,
      },
      '& .MuiListItemIcon-root': { minWidth: 30 },
      '& .MuiListItem-button:hover': { background: '#ddd' },

      '& .MuiListItem-root': {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    selectedMain: {
      background: 'grey !important',
      color: 'white',
      '& svg': {
        fill: 'white',
      },

    },
    selectedNested: {
      background: 'darkgrey !important',
      color: 'white',
    },
    selected: {
      background: 'grey !important',
      color: 'white',
      '& svg': {
        fill: 'white',
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));
  return styles();
}
export default theme;

