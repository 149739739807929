import MockDataYears from '../../misc/mockDataYears';
import {
  SET_INVOICE_VIEW_ID,
  SET_SELECTED_TAB,
  SET_LOADING_CUSTOMER,
  SET_ERROR_CUSTOMER,
} from '../actions/customer';
import {
  SET_CUSTOMER_LIST_FILTER,
  SET_CUSTOMER_LIST_DATA,
  CLEAR_CUSTOMER_LIST_FILTER,
} from '../actions/customer/customerList';
import {
  SET_LATEST_INVOICES,
  SET_INVOICES,
  SET_FILTER_INVOICES,
  SET_FILTER_SUPPLIERS_INVOICES,
  SET_SUPPLIERS_INVOICES,
  SET_SUPPLIERS,
  SET_CUSTOMER_DETAIL,
  PATCH_CUSTOMER_DETAIL,
  SET_CUSTOMER_NOTES_LIST,
  SET_CUSTOMER_INVOICES_DETAIL_LIST,
  SET_CUSTOMER_FEE_ROW_LIST,
  CLEAR_CUSTOMER_DATA,
  SET_FACILITY_DETAILS,
} from '../actions/customer/customerDetail';
import {
  SET_CONNECTION_FEES_LIST,
  SET_CONNECTION_SUPPLIER_FILTER,
  SET_CONNECTION_SUPPLIER_LIST,
  CLEAR_CONNECTION_SUPPLIER_FILTER,
  SET_CONNECTION_DETAILS,
  SET_CONNECTED_USERS,
} from '../actions/customer/customerConnection';

const initialState = {
  loading: false,
  error: null,
  selectedTab: 0,
  selectedInvoiceViewId: null,
  customerListFilter: {
    search: '',
    search_invoice: '',
    page: 1,
    limit: 10,
  },
  customerList: {
    data: [],
    totalPages: 0
  },
  invoicesLatest: [],
  invoicesListFilter: {
    year: MockDataYears[0].id,
  },
  invoicesList: {
    data: [],
    totalPages: 0
  },
  supplierInvoicesListFilter: {
    year: MockDataYears[0].id,
    supplier_id: '',
    page: 1,
    limit: 10,
  },
  supplierInvoices: {
    data: [],
    totalPages: 0
  },
  suppliers: [],
  connectionSupplierFilter: {
    name: '',
    page: 1,
    limit: 10,
  },
  connectionSupplierList: {
    data: [],
    totalPages: 0
  },
  fees: [],
  customerData: {
    id: '',
    customer_seller_id: '',
    props_cust_number: '',
    pin_code: '',
    customer_type_id: '',
    name: '',
    name2: '',
    address1: '',
    address2: '',
    post_code: '',
    city: '',
    country: '',
    tel1: '',
    tel2: '',
    email1: '',
    email2: '',
    auto_giro: '',
    auto_giro_account: '',
    einvoice: '',
    ssn: '',
    props_change_date: '',
    props_changed_by: null,
    props_created: '',
    seller_id: '',
    delivery: '',
    special_cust: '',
    new_account: '',
    beevo_user_id: '',
    deceased: null,
    protected: null,
    status: '',
    customer_number: '',
    customer_fmi: '',
    updated: '',
    sync: ''
  },
  customerNotes: [],
  customerInvoicesDetailList: null,
  feeRows: {
    invoice_rows: [],
    supplier_invoice_rows: [],
  },
  connectionDetails: {
    customer_id: null,
    supplier_id: null,
    fee: false,
    fee_vat: false,
    customer_nr_at_supplier: null,
    disable_date: null,
    auto_release_value: null,
    fee_start_date: null,
  },
  facilityDetails: [],
  connectedUsers: [],
}
const CustomerReducer = function (state = initialState, action) {
  const { type, payload, } = action;

  switch (type) {
    case SET_LOADING_CUSTOMER:
      return {
        ...state,
        loading: payload,
      }
    case SET_ERROR_CUSTOMER:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: payload,
      }
    case SET_INVOICE_VIEW_ID:
      return {
        ...state,
        selectedInvoiceViewId: payload,
      }
    case SET_CUSTOMER_LIST_DATA:
      return {
        ...state,
        loading: false,
        customerList: payload,
      }
    case SET_CUSTOMER_LIST_FILTER:
      let tempCLF = JSON.parse(JSON.stringify(state.customerListFilter));
      tempCLF[payload.key] = payload.value;
      return {
        ...state,
        customerListFilter: tempCLF,
      }
    case CLEAR_CUSTOMER_LIST_FILTER:
      return {
        ...state,
        customerListFilter: {
          search: '',
          search_invoice: '',
          page: 1,
          limit: 10,
        },
      }
    case SET_LATEST_INVOICES:
      return {
        ...state,
        loading: false,
        invoicesLatest: payload,
      }
    case SET_FILTER_INVOICES:
      return {
        ...state,
        invoicesListFilter: { year: payload },
      }
    case SET_INVOICES:
      return {
        ...state,
        loading: false,
        invoicesList: payload,
      }
    case SET_FILTER_SUPPLIERS_INVOICES:
      let tempSILF = JSON.parse(JSON.stringify(state.supplierInvoicesListFilter));
      tempSILF[payload.key] = payload.value;
      return {
        ...state,
        supplierInvoicesListFilter: tempSILF,
      }
    case SET_SUPPLIERS_INVOICES:
      return {
        ...state,
        loading: false,
        supplierInvoices: payload,
      }
    case SET_SUPPLIERS:
      return {
        ...state,
        loading: false,
        suppliers: payload,
      }
    case SET_CONNECTION_FEES_LIST:
      return {
        ...state,
        loading: false,
        fees: payload,
      }
    case SET_CONNECTION_SUPPLIER_FILTER:
      let tempCSF = JSON.parse(JSON.stringify(state.connectionSupplierFilter));
      tempCSF[payload.key] = payload.value;
      return {
        ...state,
        connectionSupplierFilter: tempCSF,
      }
    case SET_CONNECTION_SUPPLIER_LIST:
      return {
        ...state,
        loading: false,
        connectionSupplierList: payload,
      }
    case CLEAR_CONNECTION_SUPPLIER_FILTER:
      return {
        ...state,
        connectionSupplierFilter: {
          name: '',
          page: 1,
          limit: 10,
        },
      }
    case SET_CUSTOMER_DETAIL:
      return {
        ...state,
        loading: false,
        error: false,
        customerData: payload,
      }
    case PATCH_CUSTOMER_DETAIL:
      let tempCD = JSON.parse(JSON.stringify(state.customerData));
      tempCD[payload.key] = payload.value;
      return {
        ...state,
        loading: false,
        error: false,
        customerData: tempCD,
      }
    case SET_CUSTOMER_NOTES_LIST:
      return {
        ...state,
        loading: false,
        customerNotes: payload,
      }
    case SET_CUSTOMER_INVOICES_DETAIL_LIST:
      return {
        ...state,
        loading: false,
        error: false,
        customerInvoicesDetailList: payload,
      }
    case SET_CUSTOMER_FEE_ROW_LIST:
      return {
        ...state,
        loading: false,
        error: false,
        feeRows: payload,
      }
    case SET_CONNECTION_DETAILS:
      return {
        ...state,
        loading: false,
        error: false,
        connectionDetails: payload,
      }
    case CLEAR_CUSTOMER_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        customerData: {
          id: '',
          customer_seller_id: '',
          props_cust_number: '',
          pin_code: '',
          customer_type_id: '',
          name: '',
          name2: '',
          address1: '',
          address2: '',
          post_code: '',
          city: '',
          country: '',
          tel1: '',
          tel2: '',
          email1: '',
          email2: '',
          auto_giro: '',
          auto_giro_account: '',
          einvoice: '',
          ssn: '',
          props_change_date: '',
          props_changed_by: null,
          props_created: '',
          seller_id: '',
          delivery: '',
          special_cust: '',
          new_account: '',
          beevo_user_id: '',
          deceased: null,
          protected: null,
          status: '',
          customer_number: '',
          customer_fmi: '',
          updated: '',
          sync: ''
        },
      }
    case SET_FACILITY_DETAILS:
      return {
        ...state,
        loading: false,
        error: false,
        facilityDetails: payload,
      }
    case SET_CONNECTED_USERS:
      return {
        ...state,
        loading: false,
        error: false,
        connectedUsers: payload,
      }

    default:
      return state;
  }
}
export default CustomerReducer;
