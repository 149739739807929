
import React, { memo, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton } from '../../components/buttons'
import { BorderSelect, SingleCheck, DatePickerWLabel, TextInput, AutoComplete } from '../../components/inputs'
import { SuppliersInvoices, } from '../../components/tables'
import { Paper, Grid } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import Pagination from '../../components/Pagination'
import {
  fetchFiltersSupplierInvoices,
  setSupplierInvoicesFilter,
  clearSupplierInvoicesFilter,
  fetchSupplierInvoicesList,
  fetchSupplierInvoiceErrorTotals,
  downloadSupplierInvoicePDF,
} from '../../redux/actions/supplierInvoices';
import translate from '../../misc/applyTranslation';
const CustomerListView = (props) => {
  const classes = theme();
  const {
    access_token,
    refresh_token,
    fetchFiltersSupplierInvoices,
    setSupplierInvoicesFilter,
    clearSupplierInvoicesFilter,
    fetchSupplierInvoicesList,
    search_ok_status_list,
    search_release_status_list,
    search_suppliers_list,
    fetchSupplierInvoiceErrorTotals,
    supplierId,
    date_from,
    date_to,
    stel_unpaid,
    customer_unpaid,
    okStatus,
    releaseStatus,
    supplierInvoice,
    customerConnection,
    supplierOCR,
    page,
    totalPages,
    limit,
    data,
    totalRelese_error,
    totalOk_errors,
    downloadSupplierInvoicePDF
  } = props;
  useEffect(() => {
    fetchFiltersSupplierInvoices({
      access_token,
      refresh_token
    });
    fetchSupplierInvoiceErrorTotals({
      access_token,
      refresh_token
    })
    // eslint-disable-next-line
  }, [])
  const handleChange = (value, key) => {
    setSupplierInvoicesFilter({
      key: key,
      value: value === 'null' ? null : value,
    })
  }
  const fetchData = (e) => {
    page === 1 ? handleFetchData() : handlePagination(1)
  }
  const handleFetchData = (e) => {
    fetchSupplierInvoicesList({
      access_token,
      refresh_token,
      supplierId,
      date_from,
      date_to,
      stel_unpaid,
      customer_unpaid,
      okStatus,
      releaseStatus,
      supplierInvoice,
      customerConnection,
      supplierOCR,
      page,
      limit
    })
  }
  const clearForm = () => {
    clearSupplierInvoicesFilter({
      access_token,
      refresh_token,
    });
  }
  const handlePagination = (value) => {
    setSupplierInvoicesFilter({
      key: 'page',
      value: value,
    })
  }
  useEffect(() => {
    handleFetchData()
    // eslint-disable-next-line
  }, [page])
  const handlePDFDownload = (id, type) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type
    }).then(res => {
      try {
        let blob = new Blob([res], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {
      }
    })
  }
  return (
    <React.Fragment>
      <Grid container className={classes.fixMargin}>
        <Grid item xs={12} sm={6}> <BorderButton active={okStatus === 'not-ok-only'} text={translate('supplier-invoices', 'invoices_ok_status')}
          onClick={() => {
            if (okStatus !== 7) {
              handleChange('not-ok-only', 'okStatus')
            }
          }}
        /></Grid>
        <Grid item xs={12} sm={6}> <BorderButton active={releaseStatus === 'not-ok-only'} text={translate('supplier-invoices', 'invoices_released_status')}
          onClick={() => {
            if (releaseStatus !== 8) {
              handleChange('not-ok-only', 'releaseStatus')
            }
          }}
        /></Grid>
      </Grid>
      <Paper className={classes.titleContainer}>
        <div className={'title'}>{translate('supplier-invoices', 'supplier_invoices')}</div>
        <div className={'text'}>{translate('supplier-invoices', 'supplier_invoices_with status_errors').replace('##count##', totalOk_errors)}</div>
        <div className={'text'}>{translate('supplier-invoices', 'supplier_invoices_not_released').replace('##count##', totalRelese_error)}</div>
      </Paper>
      <Paper className={classes.titleContainer}>
        <div className={'title'}>{translate('supplier-invoices', 'search_supplier_invoice')}</div>
        <form onSubmit={(e) => { e.preventDefault(); fetchData() }}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <AutoComplete
                allowAll
                onChange={(e) => handleChange(e, 'supplierId')}
                selected={supplierId}
                items={search_suppliers_list}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerWLabel label={translate('supplier-invoices', 'label_from')} value={date_from} onChange={(e) => handleChange(e, 'date_from')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerWLabel label={translate('supplier-invoices', 'label_to')} value={date_to} onChange={(e) => handleChange(e, 'date_to')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleCheck value={stel_unpaid} text={translate('supplier-invoices', 'unpayed_by_stel')} onChange={(e) => handleChange(e, 'stel_unpaid')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SingleCheck value={customer_unpaid} text={translate('supplier-invoices', 'unpayed_by_customer')} onChange={(e) => handleChange(e, 'customer_unpaid')} />
            </Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={4}>
              <BorderSelect
                allowAll
                selected={okStatus}
                onChange={(e) => handleChange(e, 'okStatus')}
                items={search_ok_status_list}
              /></Grid>
            <Grid item xs={12} sm={4}>
              <BorderSelect
                allowAll
                selected={releaseStatus}
                onChange={(e) => handleChange(e, 'releaseStatus')}
                items={search_release_status_list}
              /></Grid>
            <Grid item xs={12} sm={4} />
            <Grid item xs={12} sm={3} style={{ margin: '10px 0px' }} >
              <TextInput placeholder={translate('supplier-invoices', 'placeholder_supplier_invoice')} value={supplierInvoice} onChange={(e) => handleChange(e.target.value, 'supplierInvoice')} />
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: '10px 0px' }} >
              <TextInput placeholder={translate('supplier-invoices', 'placeholder_customer_connection')} value={customerConnection} onChange={(e) => handleChange(e.target.value, 'customerConnection')} />
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: '10px 0px' }} >
              <TextInput placeholder={translate('supplier-invoices', 'placeholder_supplier_ocr')} value={supplierOCR} onChange={(e) => handleChange(e.target.value, 'supplierOCR')} />
            </Grid>
            <Grid item xs={12} sm={3} style={{ margin: '10px 0px' }} >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <BorderButton text={translate('', 'button_reset')} onClick={clearForm} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BorderButton type='submit' text={translate('', 'button_search')} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <SuppliersInvoices data={data} downloadPDF={handlePDFDownload} />
      <div className={classes.fixMargin} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ maxWidth: 250 }}>
        </div>
        <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
      </div>


    </React.Fragment >
  )
}

CustomerListView.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  fetchFiltersSupplierInvoices: PropTypes.func.isRequired,
  fetchSupplierInvoiceErrorTotals: PropTypes.func.isRequired,
  setSupplierInvoicesFilter: PropTypes.func.isRequired,
  fetchSupplierInvoicesList: PropTypes.func.isRequired,
  search_ok_status_list: PropTypes.array.isRequired,
  search_release_status_list: PropTypes.array.isRequired,
  search_suppliers_list: PropTypes.array.isRequired,
}

const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  search_ok_status_list: store.supplierInvoices.search_ok_status_list,
  search_release_status_list: store.supplierInvoices.search_release_status_list,
  search_suppliers_list: store.supplierInvoices.search_suppliers_list,
  flag: store.supplierInvoices.supplierInvoicesFilter.flag,
  supplierId: store.supplierInvoices.supplierInvoicesFilter.supplierId,
  date_from: store.supplierInvoices.supplierInvoicesFilter.date_from,
  date_to: store.supplierInvoices.supplierInvoicesFilter.date_to,
  stel_unpaid: store.supplierInvoices.supplierInvoicesFilter.stel_unpaid,
  customer_unpaid: store.supplierInvoices.supplierInvoicesFilter.customer_unpaid,
  okStatus: store.supplierInvoices.supplierInvoicesFilter.okStatus,
  releaseStatus: store.supplierInvoices.supplierInvoicesFilter.releaseStatus,
  supplierInvoice: store.supplierInvoices.supplierInvoicesFilter.supplierInvoice,
  customerConnection: store.supplierInvoices.supplierInvoicesFilter.customerConnection,
  supplierOCR: store.supplierInvoices.supplierInvoicesFilter.supplierOCR,
  page: store.supplierInvoices.supplierInvoicesFilter.page,
  totalPages: store.supplierInvoices.supplierInvoicesList.totalPages,
  limit: store.supplierInvoices.supplierInvoicesFilter.limit,
  data: store.supplierInvoices.supplierInvoicesList.data,
  totalRelese_error: store.supplierInvoices.relese_error,
  totalOk_errors: store.supplierInvoices.ok_errors,


});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchFiltersSupplierInvoices,
    setSupplierInvoicesFilter,
    clearSupplierInvoicesFilter,
    fetchSupplierInvoicesList,
    fetchSupplierInvoiceErrorTotals,
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));
