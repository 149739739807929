import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import translate from '../../misc/applyTranslation';
export default function AlertDialog(props) {
  const {
    open,
    onAccept,
    onAbort
  } = props;

  const handleClose = () => {
    if (onAbort) {
      onAbort()
    }
  };
  const handleOpen = () => {
    if (onAccept) {
      onAccept()
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{translate('', 'generic_confirmation_modal_irreversible_title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {translate('', 'generic_confirmation_modal_irreversible_text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('', 'button_cancel')}
        </Button>
        <Button onClick={handleOpen} color="primary" autoFocus>
          {translate('', 'button_confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
