
import React, { memo, useEffect, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Grid, Paper } from '@material-ui/core';
import { useHistory, useParams, withRouter } from "react-router-dom";
import HomeCart from '../../components/HomeCart';
import { fetchDashData } from '../../redux/actions/home'
import translate from '../../misc/applyTranslation';
import Group from './group';
import GroupEuro from './groupEuro';
const CustomerListView = (props) => {
  const classes = theme();
  // eslint-disable-next-line
  const history = useHistory();
  const params = useParams();
  const {
    fetchDashData,
    access_token,
    refresh_token,
    num_customers,
    sum_connected_customers,
  } = props;
  useEffect(() => {
    fetchDashData({
      access_token: access_token,
      refresh_token: refresh_token,
    })
    // eslint-disable-next-line
  }, [])
  const { lang } = params
  const handleNavigation = () => {
    history.push(`${lang}/customers`)
  }
  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <div className='titleHome'>{translate('home', 'welcome')} </div>
        <Grid container className={classes.cartContainer}>
          <Grid item xs={12} sm={6} md={6}>
            <HomeCart count={num_customers} title={translate('home', 'customers')} icon={Group} onClick={handleNavigation} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <HomeCart count={sum_connected_customers} title={translate('home', 'customers')} icon={GroupEuro} text={translate('home', 'with_invoice')} onClick={handleNavigation} />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}
CustomerListView.propTypes = {
  fetchDashData: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  num_customers: PropTypes.number.isRequired,
  sum_connected_customers: PropTypes.number.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  num_customers: store.home.num_customers,
  sum_connected_customers: store.home.sum_connected_customers,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchDashData
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));
