import React, { memo } from 'react';
import theme from './style';
import { DatePicker } from "@material-ui/pickers";

const Calendar = (props) => {
  const classes = theme();
  const {
    value,
    onChange
  } = props;
  return (
    <div className={classes.calendar}>
      <DatePicker
        style={{ width: '100%' }}
        autoOk
        orientation="landscape"
        variant="static"
        disableToolbar
        openTo="date"
        disablePast
        value={value}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
}
Calendar.propTypes = {
}

export default memo(Calendar);