
import {
  SET_LOADING_SUPPLIERS,
  SET_ERROR_SUPPLIERS,
  SET_SUPPLIERS_FILTER,
  SET_SUPPLIERS_LIST,
  SET_SUPPLIER_DETAIL
} from '../actions/suppliers';

const initialState = {
  loading: false,
  error: null,
  suppliersFilter: {
    page: 1,
    limit: 10,
  },
  suppliersList: {
    data: [],
    totalPages: 0
  },
  supplierData: {}
}
const SettingsReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_LOADING_SUPPLIERS:
      return {
        ...state,
        loading: payload,
      }
    case SET_ERROR_SUPPLIERS:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_SUPPLIERS_FILTER:
      let tempSF = JSON.parse(JSON.stringify(state.suppliersFilter));
      tempSF[payload.key] = payload.value;
      return {
        ...state,
        suppliersFilter: tempSF,
      }
    case SET_SUPPLIERS_LIST:
      return {
        ...state,
        loading: false,
        suppliersList: payload,
      }
    case SET_SUPPLIER_DETAIL:
      return {
        ...state,
        loading: false,
        supplierData: payload,
      }
    default:
      return state;
  }
}
export default SettingsReducer;