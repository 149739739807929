import APICall from '../../../misc/APICall';
import { renewToken } from '../login'
export const SET_CUSTOMER_TAGS = 'SET_CUSTOMER_TAGS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_DELIVERY_TYPES = "SET_DELIVERY_TYPES";

export const fetchCustomerTags = (payload, firstTime = true) => {
  let { access_token, } = payload;
  return async dispatch => {
    // dispatch(setLoading(true))
    return await APICall({
      access_token: access_token,
      url: `customer/tags`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CUSTOMER_TAGS,
        payload: res.map(item => { return { id: item.id, text: item.status } }),
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchCustomerTags, payload, dispatch)
      } else {
        // dispatch(setError(error))
      }
    })
  }
}
export const fetchTranslations = (payload, firstTime = true) => {
  const { lang } = payload;
  return async dispatch => {
    return await APICall({
      url: `translations?language=${lang}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_TRANSLATIONS,
        payload: res === '' ? {} : res,
      })
      return true;
    }).catch(error => {
      if (firstTime) {
        dispatch(fetchTranslations(payload, false))
      }
    })
  }
}

export const fetchDeliveryTypes = payload => {
  let {access_token} = payload;

  return async dispatch => {
    return await APICall({
      access_token: access_token,
      url: "customer/deliveries",
      verb: "get"
    })
      .then(res => {
        dispatch({
          type: SET_DELIVERY_TYPES,
          payload: res
        })
      })
  }
}
