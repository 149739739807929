import APICall from '../../../misc/APICall';
import { setLoadingCustomer, setErrorCustomer } from './index';
import { fetchSupplierInvoiceByID } from '../supplierInvoices'
import { renewToken } from '../login'
export const SET_CONNECTION_FEES_LIST = 'SET_CONNECTION_FEES_LIST';
export const SET_CONNECTION_SUPPLIER_FILTER = 'SET_CONNECTION_SUPPLIER_FILTER';
export const SET_CONNECTION_SUPPLIER_LIST = 'SET_CONNECTION_SUPPLIER_LIST';
export const CLEAR_CONNECTION_SUPPLIER_FILTER = 'CLEAR_CONNECTION_SUPPLIER_FILTER';
export const SET_CONNECTION_DETAILS = 'SET_CONNECTION_DETAILS';
export const SET_CONNECTED_USERS = 'SET_CONNECTED_USERS';


export const fetchConnectionFeesList = (payload, firstTime = true) => {
  let { access_token, customer_id } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    let querystring = `?page=1&limit=5&customer_id=${customer_id}`
    return await APICall({
      access_token: access_token,
      url: `customer/suppliers${querystring}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CONNECTION_FEES_LIST,
        payload: res.data,
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchConnectionFeesList, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const setConnectionSuppliersListFilter = (payload) => {
  return {
    type: SET_CONNECTION_SUPPLIER_FILTER,
    payload: payload
  }
}
export const fetchConnectionSuppliersList = (payload, firstTime = true) => {
  let { access_token, name, page, limit } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    let querystring = `?page=${page}&limit=${limit}`
    if (name) {
      querystring += `&name=${name}`
    }
    return await APICall({
      access_token: access_token,
      url: `suppliers${querystring}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CONNECTION_SUPPLIER_LIST,
        payload: {
          data: res.data,
          totalPages: res.pages,
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchConnectionSuppliersList, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const clearSupplierFilter = (payload) => {
  let { access_token, refresh_token } = payload;
  return async dispatch => {
    dispatch({
      type: CLEAR_CONNECTION_SUPPLIER_FILTER,
    })
    dispatch(
      fetchConnectionSuppliersList({
        page: 1, limit: 10, name: '', access_token: access_token, refresh_token: refresh_token
      })
    )
  }
}
export const fetchConnectionDetails = (payload, firstTime = true) => {
  let { access_token, supplier_id, customer_id, clear } = payload;

  return async dispatch => {
    if (clear) {
      dispatch({
        type: SET_CONNECTION_DETAILS,
        payload: {
          customer_id: null,
          supplier_id: null,
          fee: false,
          fee_vat: false,
          customer_nr_at_supplier: null,
          disable_date: null,
          auto_release_value: null,
          fee_start_date: null,
        }
      })
    } else {
      dispatch(setLoadingCustomer(true))
      return await APICall({
        access_token: access_token,
        url: `supplier-invoice/connection?supplier_id=${supplier_id}&customer_id=${customer_id}`,
        verb: 'get',
      }).then(res => {
        dispatch({
          type: SET_CONNECTION_DETAILS,
          payload: { ...res, connection_status: res.disable_date ? '0' : '1', }
        })
        return true;
      }).catch(error => {
        if (firstTime && error.error === "Invalid authentication credentials") {
          renewToken(fetchConnectionDetails, payload, dispatch)
        } else {
          dispatch(setErrorCustomer(error))
        }
      })
    }
  }
}
export const submitConnectionDetail = (payload, firstTime = true) => {
  let { access_token, body, verb, supplierForm } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice/connection`,
      verb: verb,
      body: body,
    }).then(res => {
      if (!supplierForm) {
        dispatch(fetchConnectionDetails(payload))
      } else {
        dispatch(setLoadingCustomer(false))
        dispatch(fetchSupplierInvoiceByID(payload))
      }
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(submitConnectionDetail, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const disableConnectionDetail = (payload, firstTime = true) => {
  let { access_token, customer_id, supplier_id, custnr_supplier, hide_disabled_check } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    return await APICall({
      access_token: access_token,
      url: `supplier-invoice/connection?customer_id=${customer_id}&supplier_id=${supplier_id}&customer_nr_at_supplier=${custnr_supplier}&hide_disabled=${hide_disabled_check}`,
      verb: 'DELETE',
    }).then(res => {
      dispatch(fetchConnectionDetails(payload))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(disableConnectionDetail, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const fetchConnectedUsers = (payload, firstTime = true) => {
  let { access_token, customer } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    return await APICall({
      access_token: access_token,
      url: `customer/connected-users?customer_id=${customer}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CONNECTED_USERS,
        payload: res
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchConnectedUsers, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const disconnectUser = (payload, firstTime = true) => {
  let { access_token, customer, user } = payload;
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    return await APICall({
      access_token: access_token,
      url: `user/disconnect?user_id=${user}&customer_id=${customer}`,
      verb: 'DELETE',
    }).then(res => {
      dispatch(fetchConnectedUsers(payload))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(disconnectUser, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}
export const connectUser = (payload, firstTime = true) => {
  let { access_token, ssn, customer } = payload;
  var formdata = new FormData();
  formdata.append("ssn", ssn);
  formdata.append("customer_id", customer);
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    return await APICall({
      access_token: access_token,
      url: `user/connect`,
      verb: 'post',
      body: formdata,
    }).then(res => {
      dispatch(fetchConnectedUsers(payload))
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(connectUser, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}