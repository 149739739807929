import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    tableContainer: {
      padding: 10,

    },
    fixMargins: {
      width: 'calc(100% + 20px)',
      marginLeft: -10,
    },
    paperPadding: {
      margin: 10,
      padding: 10,
      '& .paperTitle': {
        color: '#989898',
        textSize: 20,
        fontWeight: 400,
        marginBottom: 10,
      },
      '& .paperText': {
        color: '#989898',
        textSize: 13,
        fontWeight: 200,
      },
      '& .gridContainer': {
        width: 'calc(100% + 20px)',
        marginLeft: -10,
        '& .MuiGrid-item': {
          marginBottom: 10,
        }
      }

    },

  }));
  return styles();
}
export default theme;

