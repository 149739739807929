import {
  SET_SELECTED_MENU,
  SET_BREADCRUMBS
} from '../actions/states/index';
const initialState = {
  selectedMenu: 0,
  breadcrumbs:
  {
    pageTitle: 'Stockholms Elbolag',
    items: [
      {
        text: 'Home',
        url: '/en'
      }
    ],
  }
}
const StatesReducer = function (state = initialState, action) {
  const { type, payload, } = action;

  switch (type) {
    case SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: payload,
      }
    case SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: payload,
      }
    default:
      return state;
  }
}
export default StatesReducer;