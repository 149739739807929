export const routesAdmin = {
  home: {
    url: '/:lang',
    breadcrumbs: {
      pageTitle: 'page_title_home',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        }
      ]
    }
  },
  customers: {
    url: '/:lang/customers',
    breadcrumbs: {
      pageTitle: 'page_title_customers',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_customer',
          url: '/:lang/customers'
        }
      ]
    }
  },
  customersNew: {
    url: '/:lang/customers/add-customer',
    breadcrumbs: {
      pageTitle: 'page_title_customer_add',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_customer',
          url: '/:lang/customers'
        },
        {
          text: 'breadcrumbs_customer_add',
          url: '/:lang/customers/add-customer'
        }
      ]
    }
  },
  customerDetail: {
    url: '/:lang/customers/:id',
    breadcrumbs: {
      pageTitle: 'page_title_customer_detail',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_customer',
          url: '/:lang/customers'
        },
        {
          text: 'breadcrumbs_customer_detail',
          url: '/:lang/customers/:id'
        }
      ]
    }
  },
  customerAddInvoiceFee: {
    url: '/:lang/customers/:id/add-invoice-fee',
    breadcrumbs: {
      pageTitle: 'page_title_add_invoice_fee',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_customer',
          url: '/:lang/customers'
        },
        {
          text: 'breadcrumbs_customer_detail',
          url: '/:lang/customers/:id'
        },
        {
          text: 'breadcrumbs_add_invoice_fee',
          url: '/:lang/customers/:id/add-invoice-fee'
        }
      ]
    }
  },
  customerAddSupplierConnection: {
    url: '/:lang/customers/:id/supplier-connection/:supplier',
    breadcrumbs: {
      pageTitle: 'page_title_customer_supplier_connection',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_customer',
          url: '/:lang/customers'
        },
        {
          text: 'breadcrumbs_customer_detail',
          url: '/:lang/customers/:id'
        },
        {
          text: 'breadcrumbs_customer_supplier_connection',
          url: '/:lang/customers/:id/supplier-connection/:supplier'
        }
      ]
    }
  },
  suppliers: {
    url: '/:lang/suppliers',
    breadcrumbs: {
      pageTitle: 'page_title_suppliers',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_suppliers',
          url: '/:lang/suppliers'
        }
      ]
    }
  },
  supplierInvoices: {
    url: '/:lang/supplier-invoices',
    breadcrumbs: {
      pageTitle: 'page_title_supplier_invoices',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_supplier_invoices',
          url: '/:lang/supplier-invoices'
        }
      ]
    }
  },
  supplierInvoicesDetail: {
    url: '/:lang/supplier-invoices/:id',
    breadcrumbs: {
      pageTitle: 'page_title_user_view_supplier_invoices_detail',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_supplier_invoices',
          url: '/:lang/supplier-invoices'
        },
        {
          text: 'breadcrumbs_user_view_supplier_invoice_detail',
          url: '/:lang/supplier-invoices/:id'
        }
      ]
    }
  },
  files: {
    url: '/:lang/files',
    breadcrumbs: {
      pageTitle: 'page_title_files',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_files',
          url: '/:lang/files'
        },
      ]
    }
  },
  invoices: {
    url: '/:lang/invoices',
    breadcrumbs: {
      pageTitle: 'page_title_invoicing',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_invoicing',
          url: '/:lang/invoices'
        },
      ]
    }
  },
  invoicingInvoiceFeeManage: {
    url: '/:lang/invoices/add-invoice-fee/:id',
    breadcrumbs: {
      pageTitle: 'page_title_add_invoice_fee',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_invoicing',
          url: '/:lang/invoices'
        },
        {
          text: 'breadcrumbs_add_invoice_fee',
          url: '/:lang/invoices/add-invoice-fee/:id'
        }
      ]
    }
  },
}


export const routesCustomer = {
  home: {
    url: '/:lang',
    breadcrumbs: {
      pageTitle: 'page_title_home',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        }
      ]
    }
  },
  supplierInvoices: {
    url: '/:lang/supplier-invoices',
    breadcrumbs: {
      pageTitle: 'page_title_user_view_supplier_invoices',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_supplier_invoices',
          url: '/:lang/supplier-invoices'
        }
      ]
    }
  },
  stelInvoices: {
    url: '/:lang/stel-invoices',
    breadcrumbs: {
      pageTitle: 'page_title_user_view_stel_invoices',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_user_view_stel_invoices',
          url: '/:lang/stel-invoices'
        }
      ]
    }
  },
  myInfo: {
    url: '/:lang/my-info',
    breadcrumbs: {
      pageTitle: 'page_title_user_view_my_info',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_my_info',
          url: '/:lang/my-info'
        },
      ]
    }
  },
  contacts: {
    url: '/:lang/contacts',
    breadcrumbs: {
      pageTitle: 'page_title_user_view_contacts',
      items: [
        {
          text: 'breadcrumbs_home',
          url: '/:lang'
        },
        {
          text: 'breadcrumbs_contacts',
          url: '/:lang/contacts'
        },
      ]
    }
  },
}

export default routesCustomer;
