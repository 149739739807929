
import React from "react";
import colorConfig from "../../template/settings";

const SVG = ({
  fill = colorConfig.color1,
}) => {
  return (
<svg width="45" height="45" xmlns="http://www.w3.org/2000/svg">
 <g>
  <title>background</title>
  <rect x="-1" y="-1" width="11.57444" height="11.57444" id="canvas_background" fill="none"/>
 </g>

 <g>
  <title>Layer 1</title>
  <g id="svg_1">
   <path fill={fill} stroke="null" d="m27.0893,10.24204c2.05107,1.286 3.4893,3.46218 3.75115,5.98823c0.83648,0.39084 1.76486,0.6156 2.74915,0.6156c3.59338,0 6.50584,-2.91246 6.50584,-6.50529c0,-3.59338 -2.91246,-6.50584 -6.50584,-6.50584c-3.55906,0.00111 -6.44605,2.86153 -6.5003,6.4073zm-4.26212,13.32059c3.59338,0 6.50584,-2.91301 6.50584,-6.50584s-2.91301,-6.50529 -6.50584,-6.50529c-3.59283,0 -6.50695,2.91301 -6.50695,6.50584s2.91412,6.50529 6.50695,6.50529zm2.75967,0.44343l-5.52044,0c-4.59317,0 -8.32993,3.73732 -8.32993,8.33049l0,6.75108l0.01716,0.10574l0.46502,0.1456c4.38336,1.36959 8.19153,1.82631 11.32599,1.82631c6.1222,0 9.67074,-1.74548 9.88941,-1.85676l0.43457,-0.21978l0.0465,0l0,-6.75219c0.00166,-4.59317 -3.7351,-8.33049 -8.32827,-8.33049zm10.76353,-6.71621l-5.47782,0c-0.05923,2.19168 -0.99481,4.16524 -2.47457,5.58466c4.08276,1.21403 7.06995,5.00006 7.06995,9.472l0,2.08041c5.40862,-0.19819 8.52535,-1.73109 8.73074,-1.83406l0.43457,-0.22033l0.0465,0l0,-6.7533c0,-4.59262 -3.73676,-8.32938 -8.32938,-8.32938zm-24.93887,-0.44288c1.27271,0 2.45685,-0.37146 3.45996,-1.00422c0.31887,-2.07985 1.43381,-3.8973 3.0265,-5.13514c0.00664,-0.12179 0.01827,-0.24247 0.01827,-0.36537c0,-3.59338 -2.91301,-6.50584 -6.50473,-6.50584c-3.59393,0 -6.50584,2.91246 -6.50584,6.50584c0,3.59172 2.91191,6.50473 6.50584,6.50473zm5.84263,6.02753c-1.47256,-1.41222 -2.40481,-3.37526 -2.47291,-5.55366c-0.20317,-0.01495 -0.40412,-0.031 -0.61117,-0.031l-5.51989,0c-4.59317,0 -8.32993,3.73676 -8.32993,8.32938l0,6.75219l0.01716,0.10408l0.46502,0.1467c3.51643,1.09778 6.65476,1.60376 9.38066,1.7632l0,-2.03889c0.00111,-4.47193 2.98719,-8.25686 7.07106,-9.472z" id="svg_2"/>
  </g>
  <g id="svg_3"/>
  <g id="svg_4"/>
  <g id="svg_5"/>
  <g id="svg_6"/>
  <g id="svg_7"/>
  <g id="svg_8"/>
  <g id="svg_9"/>
  <g id="svg_10"/>
  <g id="svg_11"/>
  <g id="svg_12"/>
  <g id="svg_13"/>
  <g id="svg_14"/>
  <g id="svg_15"/>
  <g id="svg_16"/>
  <g id="svg_17"/>
  <g stroke="null" id="svg_40">
   <g stroke="null" id="svg_22" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) ">
    <g stroke="null" id="svg_23">
     <path  stroke="null" d="m6337.44887,6058.92401c-73.7,28.4 -156.5,-8.4 -184.9,-82.2c-0.5,-1.4 -1,-2.7 -1.5,-4.1l147.8,0c5.5,0 10,-4.5 10,-10s-4.5,-10 -10,-10l-153.1,0c-3,-15.8 -3.3,-32.1 -1,-48l170.1,0c5.5,0 10,-4.5 10,-10s-4.5,-10 -10,-10l-165.6,0c22.7,-75.6 102.5,-118.5 178.2,-95.8c3.3,1 6.7,2.1 10.1,3.4c5.1,2 10.9,-0.5 13,-5.7c2,-5.1 -0.5,-10.9 -5.7,-13l-0.1,0c-84,-32.3 -178.3,9.6 -210.7,93.6c-2.2,5.7 -4.1,11.5 -5.6,17.4l-39.6,0c-5.5,0 -10,4.5 -10,10s4.5,10 10,10l35.7,0c-2,16 -1.7,32.1 0.9,48l-36.6,0c-5.5,0 -10,4.5 -10,10s4.5,10 10,10l41.3,0c26.1,86.2 117.1,134.9 203.2,108.9c3.8,-1.2 7.6,-2.5 11.3,-3.9c5.1,-2 7.7,-7.8 5.7,-12.9c-2,-5.1 -7.8,-7.7 -12.9,-5.7z" id="svg_24" fill="#ffffff"/>
    </g>
   </g>
   <g stroke="null" id="svg_25" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_26" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_27" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_28" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_29" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_30" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_31" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_32" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_33" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_34" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_35" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_36" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_37" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_38" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
   <g stroke="null" id="svg_39" transform="matrix(0.04250314545597558,0,0,0.037227853659606715,-241.65889065007244,-188.34655854330032) "/>
  </g>
 </g>
</svg>
  );
};

export default SVG;
