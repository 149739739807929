import React from 'react';
import theme from './style'
import { FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';


const SingleCheck = (props) => {
  const classes = theme();
  const { text, value, onChange } = props;
  return (
    <FormControlLabel className={classes.container}
      control={
        <Checkbox
          checked={value || false}
          disableRipple
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked)
            }
          }}
          name="checkedB"
          color="primary"
        />
      }
      label={text}
    />
  );
}
SingleCheck.propTypes = {
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
}
export default SingleCheck;