export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_INVOICE_VIEW_ID = 'SET_INVOICE_VIEW_ID';
export const SET_LOADING_CUSTOMER = 'SET_LOADING_CUSTOMER';
export const SET_ERROR_CUSTOMER = 'SET_ERROR_CUSTOMER';

export const setSelectedTab = (payload) => {
  return {
    type: SET_SELECTED_TAB,
    payload: payload
  }
}
export const setInvoiceViewId = (payload) => {
  return {
    type: SET_INVOICE_VIEW_ID,
    payload: payload
  }
}
export const setLoadingCustomer = (payload) => {
  return {
    type: SET_LOADING_CUSTOMER,
    payload: payload
  }
}
export const setErrorCustomer = (payload) => {
  return {
    type: SET_ERROR_CUSTOMER,
    payload: payload
  }
}