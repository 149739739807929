import React, { memo, useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InvoicesAutoDebit as Table } from '../../../components/tables'
import Pagination from '../../../components/Pagination'
import { DatePickerWLabel, } from '../../../components/inputs';
import { BorderButton } from '../../../components/buttons';
import translate from '../../../misc/applyTranslation';
import moment from 'moment';
import { fetchAutoDebitList, setAutoDebitFilter, downloadAutogiro } from '../../../redux/actions/invoices'
const AutoDebit = (props) => {
  const classes = theme();
  const [selected, setSelected] = useState([]);
  const [date, setDate] = useState();
  const {
    fetchAutoDebitList,
    setAutoDebitFilter,
    access_token,
    refresh_token,
    autoDebitFilter,
    autoDebitList,
    downloadAutogiro,
  } = props;

  const { data, totalPages } = autoDebitList;
  const { page, limit } = autoDebitFilter;

  useEffect(() => {
    fetchAutoDebitList({
      access_token,
      refresh_token,
      page,
      limit,
    })
    // eslint-disable-next-line
  }, [page])
  const handlePagination = (newPage) => {
    if (newPage !== page) {
      setSelected([])
      setAutoDebitFilter({
        key: 'page',
        value: newPage
      })
    }
  }
  const handleSelection = (id) => {
    let tempSel = JSON.parse(JSON.stringify(selected));
    let ind = tempSel.findIndex(item => item === id);
    if (ind > -1) {
      tempSel.splice(ind, 1);
    } else {
      tempSel.push(id);
    }
    setSelected(tempSel)
  }
  const handleDownload = () => {
    if (selected.length && date) {
      let batchQS = '';
      selected.forEach(item => {
        batchQS += `&batch_ids[]=${item}`
      })
      downloadAutogiro({
        access_token,
        refresh_token,
        date: date,
        batches: batchQS
      }).then(res => {
        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        a.href = url;
        a.download = `AG_FAKTURA_STH_${moment(date).format('YYYYMMDD')}.txt`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })

    }
  }
  return (
    <React.Fragment>
      <div className={classes.paperPadding}>
        <Table
          title={translate('invoices', 'autodebit_table_title')}
          subTitle={translate('invoices', 'autodebit_table_subtitle')}
          description={translate('invoices', 'autodebit_table_description')}
          data={data}
          selected={selected}
          onClick={handleSelection}
        />
        <div className='pagContainer'>
          <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
        </div>
      </div >
      <div className={classes.paperPadding}>
        <Paper className='paper'>
          <div className='title'>{translate('invoices', 'autodebit_drawn_date_label')}</div>
          <div className='date'>
            <DatePickerWLabel value={date} onChange={e => setDate(e)} />
          </div>
          <div className='button'>
            <BorderButton text={translate('invoices', 'autodebit_generate_autogiro_button')} onClick={handleDownload} />
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
}
AutoDebit.propTypes = {
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  autoDebitFilter: store.invoices.autoDebitFilter,
  autoDebitList: store.invoices.autoDebitList,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchAutoDebitList,
    setAutoDebitFilter,
    downloadAutogiro,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(AutoDebit));