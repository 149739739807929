
import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomerDrawer from './customer';
import AdministratorDrawer from './admninistrator';
const DrawerMenu = (props) => {
  const {
    user_admin,
    isResponsive,
    closeMenu
  } = props;
  if (user_admin) {
    return <AdministratorDrawer responsive={isResponsive} closeMenu={closeMenu} />
  } else {
    return <CustomerDrawer closeMenu={closeMenu} />

  }
}
DrawerMenu.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  user_admin: PropTypes.bool.isRequired,
  isResponsive: PropTypes.bool.isRequired,

}
const mapStateToProps = store => ({
  user_admin: store.login.user_admin,
});
export default connect(mapStateToProps, null)(memo(DrawerMenu));
