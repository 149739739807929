import APICall from '../../../misc/APICall';
import { renewToken } from '../login';
export const SET_DASH_DATA = 'SET_DASH_DATA';


export const fetchDashData = (payload, firstTime = true) => {
  let { access_token } = payload;
  return async dispatch => {
    // dispatch(setLoading(true))
    return await APICall({
      access_token: access_token,
      url: `dash`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_DASH_DATA,
        payload: res
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error==="Invalid authentication credentials") {
        renewToken(fetchDashData, payload, dispatch)
      }
    })
  }
}