import APICall from '../../../misc/APICall';
import {setLoadingCustomer, setErrorCustomer} from './index';
import {renewToken} from '../login';
import moment from 'moment';
import {setLoadingSupplierInvoices} from "../supplierInvoices";

export const SET_LATEST_INVOICES = 'SET_LATEST_INVOICES';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_FILTER_INVOICES = 'SET_FILTER_INVOICES';
export const SET_FILTER_SUPPLIERS_INVOICES = 'SET_FILTER_SUPPLIERS';
export const SET_SUPPLIERS_INVOICES = 'SET_SUPPLIERS_INVOICES';
export const SET_FILTER_SUPPLIERS = 'SET_FILTER_SUPPLIERS';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_CUSTOMER_DETAIL = 'SET_CUSTOMER_DETAIL';
export const PATCH_CUSTOMER_DETAIL = 'PATCH_CUSTOMER_DETAIL';
export const SET_CUSTOMER_NOTES_LIST = 'SET_CUSTOMER_NOTES_LIST';
export const SET_CUSTOMER_INVOICES_DETAIL_LIST = 'SET_CUSTOMER_INVOICES_DETAIL_LIST';
export const SET_CUSTOMER_FEE_ROW_LIST = 'SET_CUSTOMER_FEE_ROW_LIST';
export const CLEAR_CUSTOMER_DATA = 'CLEAR_CUSTOMER_DATA';
export const SET_FACILITY_DETAILS = 'SET_FACILITY_DETAILS';

const server = true ? window.location.origin : 'https://staging.cheap.cust.commerz.se';

export const fetchInvoicePDF = payload => {
    let {access_token, id} = payload;

  fetch(`${server}/api/invoice/evry_pdf?faktnr=${id}`, {
    headers: {
      'Authorization': `Bearer ${access_token}`
    }
  })
    .then(response => {
      response.blob().then(blob => {
        // Create a URL object from the Blob
        const url = window.URL.createObjectURL(blob);
        // Open the PDF file in a new window
        window.open(url);
      });
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchLatestInvoices = (payload, firstTime = true) => {
    let {access_token, customer_id} = payload;
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        return await APICall({
            access_token: access_token,
            url: `customer/invoices?page=1&limit=5&customer_id=${customer_id}&year=${moment().year()}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_LATEST_INVOICES,
                payload: res.data,
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchLatestInvoices, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const setInvoicesListFilter = (payload) => {
    return {
        type: SET_FILTER_INVOICES,
        payload: payload
    }
}
export const fetchInvoicesList = (payload, firstTime = true) => {
    let {access_token, customer_id, year} = payload;
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        return await APICall({
            access_token: access_token,
            url: `customer/invoices?page=1&limit=30&customer_id=${customer_id}&year=${year}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_INVOICES,
                payload: {
                    data: res.data,
                    totalPages: res.pages,
                }
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchInvoicesList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const setSuppliersInvoicesListFilter = (payload) => {
    return {
        type: SET_FILTER_SUPPLIERS_INVOICES,
        payload: payload
    }
}
export const fetchSuppliersInvoicesList = (payload, firstTime = true) => {
    let {access_token, customer_id, year, supplier_id, page, limit} = payload;
    let QS = supplier_id && supplier_id.length > 0 ? `&supplier_id=${supplier_id}` : ''
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        return await APICall({
            access_token: access_token,
            url: `customer/supplier_invoices?page=${page}&limit=${limit}&customer_id=${customer_id}&year=${year}${QS}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_SUPPLIERS_INVOICES,
                payload: {
                    data: res.data,
                    totalPages: res.pages,
                }
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchSuppliersInvoicesList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const setSuppliersListFilter = (payload) => {
    return {
        type: SET_FILTER_SUPPLIERS,
        payload: payload
    }
}
export const fetchSuppliersList = (payload, firstTime = true) => {
    let {access_token, customer_id} = payload;
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        return await APICall({
            access_token: access_token,
            url: `customer/suppliers?page=1&limit=999&customer_id=${customer_id}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_SUPPLIERS,
                payload: res.data.map(item => {
                    return {
                        id: item.id,
                        text: item.suppl_name
                    }
                })
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchSuppliersList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const fetchCustomerDetail = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {customer_id, access_token} = payload;
        return await APICall({
            access_token: access_token,
            url: `customer?id=${customer_id}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_CUSTOMER_DETAIL,
                payload: res
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerDetail, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const patchCustomerObject = (payload) => {
    return {
        type: PATCH_CUSTOMER_DETAIL,
        payload: payload
    }
}
export const updateCustomer = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {customer_id, access_token, data, verb} = payload;
        var formdata = new FormData();
        if (verb === 'post') {
            formdata.append("ssn", data.ssn);
        }
        if (verb !== 'post') {
            formdata.append("customer_id", customer_id);
        }
        formdata.append("name", data.name);
        formdata.append("name2", data.name2);
        formdata.append("address1", data.address1);
        formdata.append("address2", data.address2);
        formdata.append("post_code", data.post_code);
        formdata.append("city", data.city);
        formdata.append("country", data.country);
        formdata.append("status", data.status);
        formdata.append("special_cust", data.special_cust);
        formdata.append("email1", data.email1);
        formdata.append("email2", data.email2);
        formdata.append("tel2", data.tel2);
        formdata.append("tel1", data.tel1);
        formdata.append("customer_type_id", data.customer_type_id);
        formdata.append("customer_fmi", data.customer_fmi);
        formdata.append("delivery", data.delivery);
        formdata.append("auto_giro", data.auto_giro);
        // if (data.auto_giro === '1') {
        formdata.append("auto_giro_account", data.auto_giro_account);
        // }
        return await APICall({
            access_token: access_token,
            url: `customer/${verb === 'post' ? 'create' : 'update'}`,
            verb: verb === 'post' ? verb : 'patch',
            body: formdata,
        }).then(res => {
            if (verb !== 'post') {
                dispatch(fetchCustomerDetail(payload))
            } else {
                dispatch(clearCustomerData())
                dispatch(setLoadingCustomer(false))
            }
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(updateCustomer, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}

export const fetchCustomerNotesList = (payload, firstTime = true) => {
    let {access_token, id} = payload;
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        APICall({
            access_token: access_token,
            url: `customer/notes?id=${id}&limit=9000&page=1`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_CUSTOMER_NOTES_LIST,
                payload: res.data
            })
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerNotesList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const patchCustomerNote = (payload, firstTime = true) => {
    let {access_token, note_id, text} = payload;
    var formdata = new FormData();
    formdata.append("note_id", note_id);
    formdata.append("note", text);
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        APICall({
            access_token: access_token,
            url: `customer/note`,
            verb: 'PATCH',
            body: formdata,
        }).then(res => {
            dispatch(fetchCustomerNotesList(payload))
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerNotesList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const createCustomerNote = (payload, firstTime = true) => {
    let {access_token, id, text} = payload;
    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("note", text);

    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        APICall({
            access_token: access_token,
            url: `customer/note`,
            verb: 'POST',
            body: formdata,
        }).then(res => {
            dispatch(fetchCustomerNotesList(payload))
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerNotesList, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const resetCustomerInvoiceDetail = () => {
    return {
        type: SET_CUSTOMER_INVOICES_DETAIL_LIST,
        payload: null
    }
}
export const fetchCustomerInvoiceDetail = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {id, access_token} = payload;
        return await APICall({
            access_token: access_token,
            url: `customer/invoice?invoice_id=${id}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_CUSTOMER_INVOICES_DETAIL_LIST,
                payload: res
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerDetail, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const fetchCustomerFeeRow = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {id, access_token} = payload;
        return await APICall({
            access_token: access_token,
            url: `customer/invoice-rows?customer_id=${id}`,
            verb: 'get',
        }).then(res => {
            dispatch({
                type: SET_CUSTOMER_FEE_ROW_LIST,
                payload: res
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchCustomerFeeRow, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const addCustomerFeeRow = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {access_token} = payload;
        var formdata = new FormData();
        if (payload.invoice_row_id) {
            formdata.append("invoice_row_id", payload.invoice_row_id);
        } else {
            formdata.append("customer_id", payload.customer_id);
        }
        formdata.append("fee_id", payload.fee_id);
        formdata.append("inv_row_amount", (payload.inv_row_amount * 100).toFixed(2));
        // formdata.append("inv_row_vat", payload.inv_row_vat);
        formdata.append("inv_row_text", payload.inv_row_text);
        return await APICall({
            access_token: access_token,
            url: `customer/fee`,
            verb: payload.new ? 'POST' : 'PATCH',
            body: formdata,
        }).then(res => {
            dispatch(fetchCustomerFeeRow(payload))
            dispatch(setLoadingCustomer(false))
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(addCustomerFeeRow, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const prolongDueDate = (payload, firstTime = true) => {

    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {access_token, date, id} = payload;
        var formdata = new FormData();
        formdata.append("invoice_id", id);
        formdata.append("due_day", date);

        return await APICall({
            access_token: access_token,
            url: `invoice/prolong`,
            verb: 'PATCH',
            body: formdata,
        }).then(res => {
            dispatch(fetchCustomerInvoiceDetail(payload))
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(addCustomerFeeRow, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const clearCustomerData = () => {
    return {
        type: CLEAR_CUSTOMER_DATA,
    }
}
export const fetchFacilityDetail = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {id, access_token} = payload;
        return await APICall({
            access_token: access_token,
            url: `customer/facilities?customer_id=${id}`,
            verb: 'get',
        }).then(res => {
            let facilities = [];
            if (res.length === 0) {
                facilities.push(res[0])
            } else {
                facilities = res;
            }
            dispatch({
                type: SET_FACILITY_DETAILS,
                payload: facilities
            })
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(fetchFacilityDetail, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
export const deleteInvoiceRow = (payload, firstTime = true) => {
    return async dispatch => {
        dispatch(setLoadingCustomer(true))
        let {inv_row_id, access_token} = payload;
        return await APICall({
            access_token: access_token,
            url: `customer/fee?invoice_row_id=${inv_row_id}`,
            verb: 'delete',
        }).then(res => {
            dispatch(fetchCustomerFeeRow(payload))
            return true;
        }).catch(error => {
            if (firstTime && error.error === "Invalid authentication credentials") {
                renewToken(deleteInvoiceRow, payload, dispatch)
            } else {
                dispatch(setErrorCustomer(error))
            }
        })
    }
}
