import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      padding: 10
    },
    dialogTitle: {
      color: settings.color2,
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: 20,
      margin: '0px 0px 15px 0px',
    },
    dialogContainer: {
      height: 250,
      overflowY: 'scroll',
      borderBottom: '3px solid #cdcdcd',
      paddingRight: 10,
    },
    dialogMessage: {
      marginBottom: 10,
      color: '#989898 !important',
      fontSize: 13,
      fontWeight: 400,
      '& .username': {
        display: 'inline-flex',
        width: '100%',
        '& .line': {
          marginLeft: 10,
          // marginRight:10,
          flexGrow: 1,
          background: '#989898',
          height: 1,
          width: '100%',
          marginTop: 'auto',
          marginBottom: 'auto',
        }
      },
      '& .message': {
        margin: '5px 0px'
      },
      '& .date': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '& .spaceBetween': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .blue': {
        color: settings.color2,
      },
    },
    chatInputContainer: {
      width: '100%',
      fontSize: 13,
      color: '#989898',
      display: 'inline-flex',
      '& .username': {
        margin: '6px 0px',
      },
      '& svg': {
        margin: 4,
        fontSize: 17,
        fill: '#989898',
      }
    },
    textinput: {
      flexGrow: 1,
      border: 'none',
      '& input': {
        fontWeight: 400,
        fontSize: 13,
        color: '#989898',
        background: 'white',
        borderRadius: 4,
        padding: '6px 12px',
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
    },
    iconHolder: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .icon': {
        cursor: 'pointer',
        fontSize: 16,
        padding: 3,
      },
      '& .save': { fill: '#4caf50' },
      '& .edit': { fill: '#2196f3' },
      '& .close': { fill: '#f44336' },
    },
    textinputInline: {
      width: '100%',
      flexGrow: 1,
      border: 'none',
      '& input': {
        fontWeight: 400,
        fontSize: 13,
        color: '#989898',
        background: 'white',
        borderRadius: 4,
        padding: '6px 12px',
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
    },
  }));
  return styles();
}
export default theme;

