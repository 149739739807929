import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      padding: 20,
      '& .title': {
        color: '#989898',
        fontWeight: 500,
        fontSize: 20,
        marginBottom: 20,
      },
      '& .label': {
        color: '#989898',
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 20,
      },
      '& .text': {
        color: '#989898',
        fontWeight: 300,
        fontSize: 14,
        marginBottom: 20,
      }
    }
  }));
  return styles();
}
export default theme;

