import React from 'react';
import theme from './style'
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import clsx from 'clsx'

const SearchButton = (props) => {
  const classes = theme();
  const { onClick, type, } = props;
  return (

    <Button
      // variant="outlined"
      type={type || 'button'}
      className={classes.container} onClick={(e) => { if (onClick) onClick(e) }}>
      <SearchIcon className={classes.buttons} />
    </Button>
  );
}
SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const RefreshButton = (props) => {
  const classes = theme();
  const { onClick } = props;
  return (
    <Button className={classes.container} onClick={(e) => { if (onClick) onClick(e) }}>
      <RefreshIcon className={classes.buttons} />
    </Button>
  );
}
RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const SaveIconButton = (props) => {
  const classes = theme();
  const { onClick, disabled } = props;
  return (
    <Button disabled={disabled} className={clsx(classes.container, classes.borderBlue, disabled ? classes.disabled : '')} onClick={(e) => { if (onClick) onClick(e) }}>
      <SaveIcon className={classes.buttons} />
    </Button>
  );
}
SaveIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}


const DeleteIconButton = (props) => {
  const classes = theme();
  const { onClick } = props;
  return (
    <Button className={`${classes.container} ${classes.red} `} onClick={(e) => { if (onClick) onClick(e) }}>
      <DeleteIcon className={classes.buttons} />
    </Button>
  );
}
DeleteIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export { SearchButton, RefreshButton, SaveIconButton, DeleteIconButton };

