import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';

const StatusChips = (props) => {
  const classes = theme();
  const {
    type,
    label,
    onClick
  } = props;
  switch (type) {
    case 'green':
      return (
        <Chip size="small" label={label} className={`${classes.chip} ${classes.green}`} />
      );
    case 'red':
      return (
        <Chip size="small" label={label} className={`${classes.chip} ${classes.red}`} />
      );
    case 'grey':
    case 'gray':
      return (
        <Chip size="small" label={label} className={`${classes.chip} ${classes.grey}`} />
      );
    case 'blue':
      return (
        <Chip size="small" label={label} className={`${classes.chip} ${classes.blue}`}  onClick={() => { if (onClick) { onClick() } }}  />
      );
    case 'border_blue':
      return (
        <Chip size="small" label={label} className={`${classes.chip} ${classes.border_blue}`} onClick={(id)=>{if(onClick){onClick()}}}/>
      );
    default:
      return null;
  }
}
StatusChips.propTypes = {
  type: PropTypes.string.isRequired,
}

export default memo(StatusChips);