
import React, { memo, useEffect, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderButton, BackButton } from '../../components/buttons';
import { Paper, Grid, } from '@material-ui/core';
import { useHistory, withRouter, useParams } from "react-router-dom";
import NotesBox from '../../components/NotesBox'
import PDFPreview from '../../components/PDFPreview'
import {
  fetchSupplierInvoiceByID,
} from '../../redux/actions/supplierInvoices';
import translate from '../../misc/applyTranslation'
import SupplierConnectionComp from '../../components/SupplierInvoicesDetailComponents/SupplierConnection';
import CustomerDetails from '../../components/SupplierInvoicesDetailComponents/CustomerDetails';
import SupplierDetails from '../../components/SupplierInvoicesDetailComponents/SupplierDetails';
import InvoiceDetails from '../../components/SupplierInvoicesDetailComponents/InvoiceDetails';
import STELInvoiceDetails from '../../components/SupplierInvoicesDetailComponents/STELInvoiceDetails';
import StatusPanel from '../../components/SupplierInvoicesDetailComponents/StatusPanel';
import Dismiss from '../../components/SupplierInvoicesDetailComponents/Dismiss';
import Message from '../../components/SupplierInvoicesDetailComponents/Message';

import clsx from 'clsx';
const CustomerListView = (props) => {
  const classes = theme();
  const history = useHistory();
  const params = useParams();
  const [loaded, setLoaded] = useState(false)
  const {
    fetchSupplierInvoiceByID,
    access_token,
    refresh_token,
    batch_data,
    supplier_connection_data,
    customer_data
  } = props;


  useEffect(() => {
    fetchSupplierInvoiceByID({
      id: params.id,
      access_token,
      refresh_token
    }).then(res => {
      if (res) {
        setLoaded(true);
      } else {
        history.goBack();
      }
    })
    // eslint-disable-next-line
  }, [])

  const handleNavigationToFees = () => {
    history.push(`/${params.lang}/customers/${customer_data.id}/add-invoice-fee`)
  }
  const notConnected = supplier_connection_data.length === 0;
  const notInvoiced = Object.keys(batch_data).length === 0 && !notConnected;
  const invoiced = Object.keys(batch_data).length > 0;
  if (loaded) {
    return (
      <React.Fragment>
        <div className={classes.backContainer}>
          <BackButton onClick={e => history.goBack()} text={translate('', 'button_back')} />
        </div>

        {invoiced &&
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.topPaper}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <CustomerDetails />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SupplierDetails />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InvoiceDetails />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <STELInvoiceDetails />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper className={classes.statusPaper}>
                <StatusPanel />
              </Paper>
              <Paper className={classes.feesPaper}>
                <BorderButton text={translate('', 'edit_fees')} onClick={handleNavigationToFees} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.notesPaper}>
                <NotesBox />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Paper className={classes.pdfPaper}>
                <PDFPreview id={params.id} />
              </Paper>
            </Grid>
          </Grid>
        }
        {notInvoiced &&
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.topPaper}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <CustomerDetails />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SupplierDetails />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <InvoiceDetails />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <STELInvoiceDetails />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper className={classes.statusPaper}>
                <StatusPanel />
              </Paper>
              <Paper className={classes.notesPaper}>
                <NotesBox />
              </Paper>
              <Paper className={classes.feesPaper}>
                <BorderButton text={translate('', 'edit_fees')} onClick={handleNavigationToFees} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Paper className={classes.pdfPaper}>
                <PDFPreview id={params.id} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.dismissPaper}>
                <Dismiss />
              </Paper>
              {/* <Paper className={classes.dismissPaper}>
                <Message />
              </Paper> */}
            </Grid>
          </Grid>
        }
        {notConnected &&
          <Grid container>
            <Grid item xs={12} sm={7}>
              <SupplierConnectionComp />
              {/* <Paper className={clsx(classes.dismissPaper, classes.messageBoxNotConnected)}>
                <Message />
              </Paper> */}
            </Grid>
            <Grid item xs={12} sm={5}>
              <Paper className={clsx(classes.topPaper, classes.invoiceDataNotConnected)}>
                <Grid container>
                  <Grid item xs={12}>
                    <InvoiceDetails />
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={clsx(classes.pdfPaper, classes.pdfPaperNotConnected)}>
                <PDFPreview id={params.id} />
              </Paper>
              <Paper className={clsx(classes.dismissPaper, classes.dismissNotConnected)}>
                  <Dismiss />
                </Paper>
            </Grid>
            </Grid>
        }

      </React.Fragment >
    )
  } else {
    return false;
  }
}
CustomerListView.propTypes = {
          fetchSupplierInvoiceByID: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
          access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  batch_data: store.supplierInvoices.supplierInvoiceDetail.batch_data,
  supplier_connection_data: store.supplierInvoices.supplierInvoiceDetail.supplier_connection_data,
  customer_data: store.supplierInvoices.supplierInvoiceDetail.customer_data,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
          fetchSupplierInvoiceByID,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));