import APICall from '../../../misc/APICall';
import { renewToken } from '../login';
export const SET_SUPPLIERS_FILTER = 'SET_SUPPLIERS_FILTER';
export const SET_SUPPLIERS_LIST = 'SET_SUPPLIERS_LIST';
export const SET_LOADING_SUPPLIERS = 'SET_LOADING_SUPPLIERS';
export const SET_ERROR_SUPPLIERS = 'SET_ERROR_SUPPLIERS';
export const SET_SUPPLIER_DETAIL = 'SET_SUPPLIER_DETAIL';

export const setLoadingSuppliers = (payload) => {
  return {
    type: SET_LOADING_SUPPLIERS,
    payload: payload
  }
}
export const setErrorSuppliers = (payload) => {
  return {
    type: SET_ERROR_SUPPLIERS,
    payload: payload
  }
}
export const setSuppliersFilter = (payload) => {
  return {
    type: SET_SUPPLIERS_FILTER,
    payload: payload
  }
}
export const fetchSuppliersList = (payload, firstTime = true) => {
  let { access_token, page, limit } = payload;
  return async dispatch => {
    dispatch(setLoadingSuppliers(true))
    return await APICall({
      access_token: access_token,
      url: `suppliers?page=${page}&limit=${limit}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIERS_LIST,
        payload: {
          data: res.data,
          pageTotal: res.pages
        }
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchSuppliersList, payload, dispatch)
      } else {
        dispatch(setErrorSuppliers(error))
      }
    })
  }
}
export const fetchSuppliersById = (payload, firstTime = true) => {
  let { access_token, id } = payload;
  return async dispatch => {
    dispatch(setLoadingSuppliers(true))
    return await APICall({
      access_token: access_token,
      url: `supplier?supplier_id=${id}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_SUPPLIER_DETAIL,
        payload: res
      })
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchSuppliersById, payload, dispatch)
      } else {
        dispatch(setErrorSuppliers(error))
      }
    })
  }
}