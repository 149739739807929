import React, {memo, useEffect, useState} from 'react';

import {Paper, Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import theme from './style';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {BorderButton, BackButton} from '../../components/buttons';
import {BorderSelect, TextInput} from '../../components/inputs';
import Label from '../../components/label';
import {useHistory, useParams} from 'react-router-dom';
import {
  fetchCustomerDetail,
  addCustomerFeeRow,
  fetchCustomerFeeRow,
  deleteInvoiceRow
} from '../../redux/actions/customer/customerDetail';
import {fetchFeesList,} from '../../redux/actions/invoices';
import moment from 'moment';
import {CustomerFees as Table} from '../../components/tables';
import translate from '../../misc/applyTranslation'

const AddInvoiceFee = (props) => {
  const classes = theme();
  const history = useHistory();
  const params = useParams();
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({
    customer_id: params.id,
    invoice_row_id: undefined,
    fee_id: undefined,
    inv_row_amount: '',
    inv_row_vat: undefined,
    inv_row_text: ''
  });
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);
  const {
    fetchCustomerDetail,
    addCustomerFeeRow,
    access_token,
    refresh_token,
    customerData,
    fetchFeesList,
    fees,
    fetchCustomerFeeRow,
    feeRows,
    deleteInvoiceRow,
  } = props;
  const handleOpen = () => {
    setCreate(true);
    setUpdate(false);
    setOpen(true);
  }
  const handleClose = () => {
    setData({
      customer_id: params.id,
      invoice_row_id: undefined,
      fee_id: undefined,
      inv_row_amount: '',
      inv_row_vat: undefined,
      inv_row_text: ''
    })
    setOpen(false)
  }
  const handleChangeFee = (e) => {
    let row_amount;
    if(update) {
      row_amount = data.inv_row_amount;
    }
    else {
      row_amount = fees.find(item => item.id === e).fee_amount / 100;
    }
    setData({...data, fee_id: e, inv_row_text: fees.find(item => item.id === e).fee_name, inv_row_amount: row_amount})
  }
  const handleValueChange = (value, key) => {
    let lastChar = value.charAt(value.length - 1);
    if (/^\d+$/.test(lastChar) || value === '-' || value.length === 0 || lastChar === '.') {
      setData({...data, [key]: value})
    }
  }
  const handleSubmit = () => {
    addCustomerFeeRow({
      id: params.id,
      access_token,
      refresh_token,
      new: data.invoice_row_id ? false : true,
      ...data,
    })
    handleClose();
  }
  const handleEdit = (row) => {
    setUpdate(true);
    setCreate(false);
    setData({
      customer_id: row.customer_id,
      invoice_row_id: row.id,
      fee_id: row.fee_type_id,
      inv_row_amount: row.inv_row_amount / 100,
      inv_row_vat: row.inv_row_vat,
      inv_row_text: row.inv_row_text
    })
    setOpen(true)
  }
  const handleDelete = (id) => {
    deleteInvoiceRow({
      access_token,
      refresh_token,
      inv_row_id: id,
      id: params.id,
    })
  }
  useEffect(() => {
    fetchFeesList({
      access_token,
      refresh_token,
    })
    fetchCustomerDetail({
      access_token,
      refresh_token,
      customer_id: params.id,
    })
    fetchCustomerFeeRow({
      access_token,
      refresh_token,
      id: params.id,
    })
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <div className={classes.backContainer}>
        <BackButton onClick={e => history.goBack()} text={translate('', 'button_back')}/>
      </div>
      <Paper elevation={4} className={classes.clientBox}>
        <div
          className='title'>{`${translate('edit-fee', 'label_customer')} ${customerData.name} ${customerData.name2}`}</div>
        <div>{`${translate('edit-fee', 'label_customer_id')} ${customerData.props_cust_number}`}</div>
        <div>{customerData.address1}</div>
        <div>{`${customerData.post_code} ${customerData.city}`}</div>
        <div>{customerData.country}</div>
        <div>{customerData.email1}</div>

        <Table data={feeRows.invoice_rows} onEdit={handleEdit} onDelete={handleDelete}/>
        <Grid container className='buttonGroup'>
          <Grid item xs={12} sm={3}>
            <BorderButton onClick={handleOpen} text={translate('', 'button_add')}/>
          </Grid>
        </Grid>
      </Paper>


      <Paper elevation={4} className={classes.invoiceBox}>
        <Typography
          className='title'>{translate('edit-fee', 'panel_title_supplier_fees_set_on_customer_connection')}</Typography>
        {feeRows.supplier_invoice_rows.map(item =>
          <Grid container key={item.id}>
            <Grid item xs={12} sm={4}>
              {item.supplier_name}
            </Grid>
            <Grid item xs={12} sm={4}>
              {item.inv_row_vat}
            </Grid>
            <Grid item xs={12} sm={4}>
              {item.fee_start_date ? moment.unix(item.fee_start_date).format('YYYY-MM-DD') : '-'}
            </Grid>
          </Grid>
        )}
      </Paper>


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{translate('edit-fee', 'dialog_title_manage_fee')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container className='buttonGroup'>
            <Grid item xs={12} sm={6}>
              <Label text={translate('edit-fee', 'dialog_label_fees')}/>
              <BorderSelect
                selected={data.fee_id}
                items={fees.map(item => {
                  return {id: item.id, text: item.fee_name}
                })}
                onChange={handleChangeFee}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Label text={translate('edit-fee', 'dialog_label_amount')}/>
              <TextInput value={data.inv_row_amount} placeholder=''
                         onChange={(e) => handleValueChange(e.target.value, 'inv_row_amount')}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <BorderButton onClick={handleClose} text={translate('', 'button_cancel')}/>
          <BorderButton onClick={handleSubmit} disabled={!data.inv_row_amount || !data.fee_id}
                        text={translate('', 'button_save')}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
AddInvoiceFee.propTypes = {
  fetchCustomerDetail: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  customerData: PropTypes.object.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  customerData: store.customer.customerData,
  fees: store.invoices.fees,
  feeRows: store.customer.feeRows,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchCustomerDetail,
    fetchFeesList,
    addCustomerFeeRow,
    fetchCustomerFeeRow,
    deleteInvoiceRow
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(AddInvoiceFee));
