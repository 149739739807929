import React, { memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import translate from '../../../misc/applyTranslation'
import toMoney from '../../../misc/serializeToMoney'
import moment from 'moment';
import {
  downloadSupplierInvoicePDF,
} from '../../../redux/actions/supplierInvoices';
const OutboundInvoice = (props) => {
  const classes = theme();
  const {
    data,
    downloadPDF,
    access_token,
    refresh_token,
    downloadSupplierInvoicePDF
  } = props;

  const handlePDFDownload = (e,id) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type: 'pdfSupplierInvoice'
    }).then(res => {
      try {
        let blob = new Blob([res], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {

      }
    })
  }
  return (
    <React.Fragment>

      <TableContainer component={Paper} className={classes.container} >
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_supplier_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_invoice_nr_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_invoice_day_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_due_day_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_cust_supplier_id_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_amount_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_ocr_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_ok_status')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_payed_header')}</TableCell>
              <TableCell>{translate('customers/detail', 'supplier_invoices_table_view_header')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.suppl_name}</TableCell>
                <TableCell>{row.sup_inv_nr}</TableCell>
                <TableCell>{row.sup_inv_day}</TableCell>
                <TableCell>{row.sup_due_day}</TableCell>
                <TableCell>{row.cust_nr_at_supplier}</TableCell>
                <TableCell>{toMoney(row.amount_total)}</TableCell>
                <TableCell>{row.sup_ocr}</TableCell>
                <TableCell align='center'><StatusChips type={row.ok_status_color} label={row.ok_status} /> </TableCell>
                <TableCell align='center'><StatusChips type={row.sup_payed !== '0' && !!row.sup_payed ? 'green' : 'red'} label={row.sup_payed !== '0' && !!row.sup_payed ?
                  row.sup_payed :
                  translate('', 'string_paid_type_false')
                } />
                </TableCell>
                <TableCell align='center' className='pointer' onClick={(e) => handlePDFDownload(e, row.supplier_invoice_id, 'pdfSupplierInvoice')}><img src='/pdf-file.png' alt='PDF' /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}


OutboundInvoice.propTypes = {
  data: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(OutboundInvoice));
