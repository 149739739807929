import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    paperPadding: {
      padding: 15,
    },
    tabsHolder: {
      background: 'transparent',
      color: 'blue',
      '& .MuiTab-textColorInherit': {
        opacity: 1,
      },
    },
    selectedTab: {
      background: settings.color2,
      color: 'white'
    },
    customerContainer: {
      marginTop: 30,
      marginLeft: -10,
      width: 'calc(100% + 20px)',
    },
    paperContainer: {
      padding: 15,
      margin: '0px 10px',
      ':not(:last-child)': {
        marginBottom: 10,
      }
    },
    fixMargins: {
      marginTop: 15,
      width: 'calc(100% + 20px)',
    },

    //customer invoice list
    customerInvoiceListContainer: {
      marginTop: 30,
    },
    customerInvoiceListHeader: {
      padding: '15px 15px 0px 15px',
      color: '#989898',
      fontSize: 20,
      fontWeight: 400,
      '& p': {
        padding: '10px 0px',
      },
      '& .forceUppercase': {
        textTransform: 'uppercase',
        marginBottom: 20
      },
      '& span': {
        color: settings.color2,
        textTransform: 'capitalize'
      }
    },
    //customer invoice detail
    customerInvoiceDetailContainer: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: -10,
      width: 'calc(100% + 20px)',
      '& div': {
        maxWidth: 150,

      }
    },
    paginationContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: 'calc(100% + 20px)',
      marginLeft: -10
    },
    editSupplierConnectionAddContainer: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: -10,
      width: 'calc(100% + 20px)',
      '& div': {
        maxWidth: 300,
      }
    },
    saveButton: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        maxWidth: 150,
      }
    }
  }));
  return styles();
}
export default theme;

