import React, { memo } from 'react';

import { Paper } from '@material-ui/core';
import Tabs from '../../components/Tabs'
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setSelectedTab } from '../../redux/actions/invoices';
import UpcomingInvoices from './UpcomingInvoices';
import HandleFees from './HandleFees';
import HandleBatch from './HandleBatch';
import AutoDebit from './AutoDebit';
import translate from '../../misc/applyTranslation';
const Invoices = (props) => {
  const classes = theme();
  const { setSelectedTab, selectedTab, } = props;
  return (
    <Paper className={classes.paperPadding}>
      <Tabs
        onClick={(e) => setSelectedTab(e)}
        selected={selectedTab}
        items={
          [
            {
              text: translate('invoices', 'tabs_upcoming_invoices'),
              id: 0
            },
            {
              text: translate('invoices', 'tabs_handle_fees'),
              id: 1
            },
            {
              text: translate('invoices', 'tabs_approve_delete_batch'),
              id: 2
            },
            {
              text: translate('invoices', 'tabs_create_autodebit'),
              id: 3
            },
          ]
        } />
      {(selectedTab === 0 || !selectedTab) && <UpcomingInvoices />}
      {selectedTab === 1 && <HandleFees />}
      {selectedTab === 2 && <HandleBatch />}
      {selectedTab === 3 && <AutoDebit />}
    </Paper >
  );
}
Invoices.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  selectedInvoiceViewId: PropTypes.number
}
const mapStateToProps = store => ({
  selectedTab: store.invoices.selectedTab,
  selectedInvoiceViewId: store.invoices.selectedInvoiceViewId
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSelectedTab }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(Invoices));