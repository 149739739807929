import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    textinputContainer: {
      width: 'calc(100% - 20px)',
      fontWeight: 400,
      fontSize: 14,
      margin: '0px 10px',
      border: 'none',
      display: 'inline-flex',
      '& .textinput': {
        flexGrow: 1,
      },
      '& .Mui-disabled': {
        background: '#eee !important',
        cursor: 'not-allowed'
      },
      '& input': {
        height: 20,
        background: 'white',
        borderRadius: '4px 0px 0px 4px',
        padding: '6px 12px',
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
      },
      '& input:focus': {
        border: `1px solid ${settings.color3}`,
        '-webkit-box-shadow': `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${settings.color3}`,
        boxShadow: `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${settings.color3}`,
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
      '& .note': {
        background: settings.color2,
        borderRadius: '0px 4px 4px 0px',
        color: settings.color6,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        fontWeight: 400,
      }
    },
    invalid: {
      '& input': {
        border: '2px solid #ff524d !important',
      },
      '& .note ': {
        background: '#ff524d',
      }
    },
  }));
  return styles();
}
export default theme;

