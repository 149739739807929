import React, { memo, useEffect } from 'react';

import { Paper, Grid, Typography } from '@material-ui/core';

import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderSelect } from '../../components/inputs';
import { CustomerVersionSupplierInvoices as Table } from '../../components/tables';
import Pagination from '../../components/Pagination';
import { setInvoiceViewId } from '../../redux/actions/customer';
import { setSuppliersInvoicesListFilter, fetchSuppliersInvoicesList, fetchSuppliersList } from '../../redux/actions/customer/customerDetail';
import MockDataYears from '../../misc/mockDataYears';
import translate from '../../misc/applyTranslation'
const CustomerInvoiceList = (props) => {
  const classes = theme();
  const {
    setSuppliersInvoicesListFilter,
    fetchSuppliersInvoicesList,
    fetchSuppliersList,
    supplier_id,
    year,
    access_token,
    refresh_token,
    suppliers,
    supplierInvoices,
    page,
    limit,
    totalPages,
    selectedCustomer,
  } = props;


  const handleSelectChange = (value, key) => {
    setSuppliersInvoicesListFilter({
      value: value,
      key: key,
    })
  }
  const handlePagination = (value) => {
    setSuppliersInvoicesListFilter({
      key: 'page',
      value: value,
    })
  }
  useEffect(() => {
    fetchSuppliersList({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: selectedCustomer,
    })
    // eslint-disable-next-line
  }, [selectedCustomer])
  
  useEffect(() => {
    if (supplier_id.length > 0) {
      fetchSuppliersInvoicesList({
        customer_id: selectedCustomer,
        supplier_id: supplier_id,
        year: year,
        access_token: access_token,
        refresh_token: refresh_token,
        limit: limit,
        page: page,
      })
    } else {
      if (suppliers.length > 0) {
        setSuppliersInvoicesListFilter(
          {
            value: suppliers[0].id,
            key: 'supplier_id'
          })
      }
    }
    // eslint-disable-next-line
  }, [suppliers, year, supplier_id, page,selectedCustomer])

  return (
    <React.Fragment>
      <Paper elevation={4} className={classes.customerInvoiceListContainer}>
        <div className={classes.customerInvoiceListHeader} >
          <Grid container>
            <Grid item xs={12}>
              <Typography className={'forceUppercase'}>{translate('supplier-invoices', 'invoices_userview_supplier_invoces_title')}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <BorderSelect
                    selected={supplier_id}
                    onChange={(e) => handleSelectChange(e, 'supplier_id')}
                    items={suppliers}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <BorderSelect
                    selected={year}
                    onChange={(e) => handleSelectChange(e, 'year')}
                    items={MockDataYears}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ width: 'calc(100% + 20px)' }}>
          <Table elevation={0} data={supplierInvoices} customer_id={selectedCustomer}/>
        </div>
      </Paper >
      <div className={classes.paginationContainer}>
        <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
      </div>
    </React.Fragment>
  );
}
CustomerInvoiceList.propTypes = {
  setInvoiceViewId: PropTypes.func.isRequired,
  setSuppliersInvoicesListFilter: PropTypes.func.isRequired,
  fetchSuppliersList: PropTypes.func.isRequired,
  fetchSuppliersInvoicesList: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  supplier_id: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
  totalPages: PropTypes.string.isRequired,
  supplierInvoices: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  year: store.customer.supplierInvoicesListFilter.year,
  supplier_id: store.customer.supplierInvoicesListFilter.supplier_id,
  page: store.customer.supplierInvoicesListFilter.page,
  limit: store.customer.supplierInvoicesListFilter.limit,
  supplierInvoices: store.customer.supplierInvoices.data,
  totalPages: store.customer.supplierInvoices.totalPages,
  suppliers: store.customer.suppliers,
  selectedCustomer: store.login.selectedCustomer,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setInvoiceViewId,
    setSuppliersInvoicesListFilter,
    fetchSuppliersInvoicesList,
    fetchSuppliersList
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerInvoiceList));