import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    backdrop: { zIndex: 1300 },
    circle: {
      color: settings.color2
    },
  }));
  return styles();
}
export default theme;

