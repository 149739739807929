const custStatus = [
  {
    id: '1',
    text: 'Error Duplicate',
  },
  {
    id: '2',
    text: 'Error No Connection',
  },
  {
    id: '3',
    text: 'Error Blocked',
  },
  {
    id: '4',
    text: 'Error Autogiro',
  },
  {
    id: '5',
    text: 'Customer Rejected',
  },
  {
    id: '6',
    text: 'Other Reason',
  },
]


export default custStatus;
