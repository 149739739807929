import React, { memo, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import Label from '../label';
import { TextInput, BorderSelect } from '../inputs'
import translate from '../../misc/applyTranslation';
import { patchCustomerObject } from '../../redux/actions/customer/customerDetail';
import { fetchCustomerTypes } from '../../redux/actions/invoices';
import { fetchCustomerTags } from '../../redux/actions/settings';
const CustomerPanel = (props) => {
  const classes = theme();
  const {
    access_token,
    refresh_token,
    data,
    customer_types,
    patchCustomerObject,
    delivery_types,
    auto_giro_types,
    customer_status,
    fetchCustomerTypes,
    fetchCustomerTags,
    tags,
    invalidFields
  } = props;
  const handleOnChange = (value, key) => {
    patchCustomerObject({
      key: key,
      value: value,
    })
  }
  useEffect(() => {
    fetchCustomerTypes({
      access_token,
      refresh_token,
    })
    fetchCustomerTags({
      access_token,
      refresh_token,
    })
    // eslint-disable-next-line
  }, [])
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_personal_nr')} />
        <TextInput invalid={invalidFields && invalidFields.includes('ssn')} value={data.ssn} onChange={(e) => {
          if (!isNaN(e.target.value)) {
            handleOnChange(e.target.value, 'ssn')
          }
        }} />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_name')} />
        <TextInput invalid={invalidFields && invalidFields.includes('name')} value={data.name} onChange={(e) => handleOnChange(e.target.value, 'name')} />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_name2')} />
        <TextInput value={data.name2} onChange={(e) => handleOnChange(e.target.value, 'name2')} />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_address')} />
        <TextInput invalid={invalidFields && invalidFields.includes('address1')} value={data.address1} onChange={(e) => handleOnChange(e.target.value, 'address1')} />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_address2')} />
        <TextInput value={data.address2} onChange={(e) => handleOnChange(e.target.value, 'address2')} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Label text={translate('customers/detail', 'customer_edit_panel_postal_code')} />
        <TextInput invalid={invalidFields && invalidFields.includes('post_code')} value={data.post_code} onChange={(e) => handleOnChange(e.target.value, 'post_code')} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Label text={translate('customers/detail', 'customer_edit_panel_city')} />
        <TextInput invalid={invalidFields && invalidFields.includes('city')} value={data.city} onChange={(e) => handleOnChange(e.target.value, 'city')} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Label text={translate('customers/detail', 'customer_edit_panel_country')} />
        <TextInput invalid={invalidFields && invalidFields.includes('country')} value={data.country} onChange={(e) => handleOnChange(e.target.value, 'country')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_customer_status')} />
        <BorderSelect
          invalid={invalidFields && invalidFields.includes('status')}
          onChange={(e) => handleOnChange(e, 'status')}
          selected={data.status}
          items={customer_status}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_special_cust')} />
        <BorderSelect
          onChange={(e) => handleOnChange(e, 'special_cust')}
          selected={data.special_cust}
          items={tags}
        />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_email')} />
        <TextInput value={data.email1} type='email' onChange={(e) => handleOnChange(e.target.value, 'email1')} />
      </Grid>
      <Grid item xs={12}>
        <Label text={translate('customers/detail', 'customer_edit_panel_email2')} />
        <TextInput value={data.email2} type='email' onChange={(e) => handleOnChange(e.target.value, 'email2')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_mobile')} />
        <TextInput value={data.tel1} type='tel' onChange={(e) => handleOnChange(e.target.value, 'tel1')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_homephone')} />
        <TextInput value={data.tel2} onChange={(e) => handleOnChange(e.target.value, 'tel2')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_customer_type')} />
        <BorderSelect
          invalid={invalidFields && invalidFields.includes('customer_type_id')}
          onChange={(e) => handleOnChange(e, 'customer_type_id')}
          selected={data.customer_type_id}
          items={customer_types}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_customer_fmi')} />
        <TextInput value={data.customer_fmi} onChange={(e) => handleOnChange(e.target.value, 'customer_fmi')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_delivery')} />
        <BorderSelect
          invalid={invalidFields && invalidFields.includes('delivery')}
          onChange={(e) => handleOnChange(e, 'delivery')}
          selected={data.delivery}
          items={delivery_types}
        />
      </Grid>
      <Grid item xs={12} sm={6} />
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_autogiro')} />
        <BorderSelect
          onChange={(e) => handleOnChange(e, 'auto_giro')}
          selected={data.auto_giro}
          items={auto_giro_types}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label text={translate('customers/detail', 'customer_edit_panel_account')} />
        <TextInput disabled={data.auto_giro === '0'} value={data.auto_giro_account} onChange={(e) => handleOnChange(e.target.value, 'auto_giro_account')} />
      </Grid>
    </Grid>
  );

}
CustomerPanel.propTypes = {
  patchCustomerObject: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  customer_types: PropTypes.array.isRequired,
  delivery_types: PropTypes.array.isRequired,
  auto_giro_types: PropTypes.array.isRequired,
  customer_status: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  customer_types: store.invoices.customer_types,
  delivery_types: store.settings.delivery_types,
  auto_giro_types: store.settings.auto_giro_types,
  customer_status: store.settings.customer_status,
  tags: store.settings.tags,
  data: store.customer.customerData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    patchCustomerObject,
    fetchCustomerTypes,
    fetchCustomerTags,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerPanel));