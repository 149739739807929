import React from 'react';
import theme from './style'
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';


const TextInput = (props) => {
  const classes = theme();
  const { placeholder, value, onChange, disabled, type, border, invalid } = props;
  return (
    <TextField
      type={type || 'text'}
      disabled={disabled}
      onChange={(e) => { if (onChange) onChange(e) }}
      className={`${classes.textinput} ${invalid ? classes.invalid : ''} ${border ? classes.border : ''}`}
      placeholder={placeholder}
      value={value}
    />
  );
}
TextInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  // disabled: PropTypes.bool,
  border: PropTypes.bool,
  type: PropTypes.string,
}
export default TextInput;