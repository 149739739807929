import React from 'react';
import theme from './style'
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  DatePicker as DP,
} from '@material-ui/pickers';

const DatePicker = (props) => {
  const classes = theme();
  const { label, onChange, value, maxDate, minDate, placeholder } = props;

  const handleDateChange = (date) => {
    if (onChange) {
      onChange(moment(date).format('YYYY-MM-DD'));
    }
  };
  return (
    <div className={classes.datepickerContainer}>
      {label && <div className='label'>{label}</div>}
      <DP
        className={classes.datepicker}
        disableToolbar
        autoOk
        emptyLabel={placeholder || ''}
        format="YYYY-MM-DD"
        value={value ? moment(value).format('YYYY-MM-DD') : null}
        onChange={handleDateChange}
        maxDate={maxDate || undefined}
        minDate={minDate || undefined}
      />
    </div>
  );

}
DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}
export default DatePicker;