import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
      padding: 10,
    },

    table: {
      minWidth: 650,
      width: 'calc(100%)',
      '& .firstRow': {
        '& td': { borderBottom: 'none' },
        '& >:nth-child(4) , >:nth-child(9)': {
          borderRight: '1px solid rgba(224, 224, 224, 1)'

        },
      },
      '& .row': {
        '& td': { borderBottom: 'none' },
        '& >:nth-child(1) , >:nth-child(6)': {
          borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
      },
      '& .totalRow': {
        '& td': { fontWeight: '700 !important' },
        '& >:nth-child(1) , >:nth-child(3)': {
          borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
      },
      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
        '&:nth-child(4) , &:nth-child(9)': {
          borderRight: '1px solid rgba(224, 224, 224, 1)'
        },
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 400,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
    },

  }));
  return styles();
}
export default theme;

