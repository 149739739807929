import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    button: {
      width: 'calc(100% - 20px)',
      borderRadius: 4,
      borderColor: settings.color2,
      color: settings.color1,
      borderWidth: 2,
      fontWeight: 400,
      fontSize: 14,
      textTransform: 'none',
      height: 34,
      '& svg': {
        fontSize: '16px !important'
      },
      margin: '0px 10px'
    }
  }));
  return styles();
}
export default theme;

