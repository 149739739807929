import React, { memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, withWidth, Grid } from '@material-ui/core';
import toMoney from '../../../misc/serializeToMoney'
import translate from '../../../misc/applyTranslation';
import {
  downloadSupplierInvoicePDF,
} from '../../../redux/actions/supplierInvoices';
const OutboundInvoice = (props) => {
  const classes = theme();
  const {
    customer_id,
    data,
    width,
    access_token,
    refresh_token,
    downloadSupplierInvoicePDF
  } = props;

  const handlePDFDownload = (id) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type: 'pdfSupplierInvoice',
      customer_id
    }).then(res => {
      try {
        let blob = new Blob([res], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {

      }
    })
  }

  let responsiveTest = (width === 'xs' || width === 'sm');
  if (!responsiveTest) {
    return (
      <React.Fragment>
        <TableContainer component={Paper} className={classes.container} >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>{translate('supplier-invoices', 'table_header_supplier')}</TableCell>
                <TableCell>{translate('supplier-invoices/detail', 'table_header_invoice_number')}</TableCell>
                <TableCell>{translate('supplier-invoices/detail', 'table_header_invoice_day')}</TableCell>
                <TableCell>{translate('supplier-invoices/detail', 'table_header_due_day')}</TableCell>
                <TableCell>{translate('supplier-invoices', 'table_header_amount')}</TableCell>
                <TableCell>{translate('supplier-invoices', 'table_header_supplier_invoice_view_pdf')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.supplier_invoice_id}>
                  <TableCell>{row.suppl_name}</TableCell>
                  <TableCell>{row.sup_inv_nr}</TableCell>
                  <TableCell>{row.sup_inv_day}</TableCell>
                  <TableCell>{row.sup_due_day}</TableCell>
                  <TableCell>{toMoney(row.amount_total)}</TableCell>
                  <TableCell align='center' className='pointer' onClick={() => handlePDFDownload(row.supplier_invoice_id)}><img src='/pdf-file.png' alt='PDF' /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </React.Fragment>
    );
  } else {
    return <div style={{ minHeight: 20, marginBottom: 20 }}>

      {data.map((row) => (
        <Grid container key={row.supplier_invoice_id} className={classes.responsiveContainer}>
          <Grid item xs={4} className='label'>{translate('supplier-invoices', 'table_header_supplier')}</Grid>
          <Grid item xs={8} className='text'>
            {row.suppl_name}
          </Grid>
          <Grid item xs={4} className='label'>{translate('supplier-invoices/detail', 'table_header_invoice_number')}</Grid>
          <Grid item xs={8} className='text'>
            {row.sup_inv_nr}
          </Grid>
          <Grid item xs={4} className='label'>{translate('supplier-invoices/detail', 'table_header_invoice_day')}</Grid>
          <Grid item xs={8} className='text'>
            {row.sup_inv_day}
          </Grid>
          <Grid item xs={4} className='label'>{translate('supplier-invoices/detail', 'table_header_due_day')}</Grid>
          <Grid item xs={8} className='text'>
            {row.sup_due_day}
          </Grid>
          <Grid item xs={4} className='label'>{translate('supplier-invoices', 'table_header_amount')}</Grid>
          <Grid item xs={8} className='text'>
            {toMoney(row.amount_total)}
          </Grid>
          <Grid item xs={4} className='label'>{translate('supplier-invoices', 'table_header_supplier_invoice_view_pdf')}</Grid>
          <Grid item xs={8} className='text' onClick={() => handlePDFDownload(row.supplier_invoice_id)}>
            <img src='/pdf-file.png' alt='PDF' />
          </Grid>
        </Grid>
      )
      )}
    </div>
  }
}
OutboundInvoice.propTypes = {
  data: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(OutboundInvoice));
