import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import StatusChips from '../../StatusChip';
import moment from 'moment';
import translate from '../../../misc/applyTranslation';
import toValue from '../../../misc/serializeToValue'
const LatestBatchedInvoices = (props) => {
  const classes = theme();
  const {
    title,
    data,
    handlePDF
  } = props;
  function formatNumberWithSpaces(number) {
    // Convert number to string
    let numStr = String(number);

    // Split the string into integer and decimal parts
    let [integerPart, decimalPart] = numStr.split(',');

    // Insert spaces every third non-decimal character in the integer part
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Combine integer and decimal parts with a comma
    let formattedNumber = formattedIntegerPart + (decimalPart ? ',' + decimalPart : '');

    return formattedNumber;
  }
  return (
    <TableContainer component={Paper} className={classes.container} >
      <div className='title'>{title}</div>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('invoices', 'batch_latest_table_name')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_in_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_due_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_created_day')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_filter')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_only_fees')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_number')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_sum')}</TableCell>
            <TableCell>{translate('invoices', 'batch_latest_table_xml')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.batch_id} className={!row.downloaded ? classes.bold : ''}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{moment.unix(row.inv_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.due_day).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{moment.unix(row.create_date).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{row.filter}</TableCell>
              <TableCell>{row.only_fees}</TableCell>
              <TableCell>{row.number_invoices}</TableCell>
              <TableCell>{formatNumberWithSpaces(toValue(row.sum))+" kr"}</TableCell>
              <TableCell>{!row.xml_file || row.xml_file.length === 0 ?
                <CircularProgress disableShrink size={20} /> :
                <StatusChips type={'blue'} label={translate('invoices', 'batch_latest_table_donload_xml')} onClick={() => handlePDF(row.batch_id)} />
              }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
LatestBatchedInvoices.propTypes = {
  data: PropTypes.array.isRequired,
  handlePDF: PropTypes.func.isRequired,
}

export default memo(LatestBatchedInvoices);
