export const SET_FORM_FIELDS = 'SET_FORM_FIELDS';
export const SET_LOADING_LOGIN = 'SET_LOADING_LOGIN';
export const SET_ERROR_LOGIN = 'SET_ERROR_LOGIN';
export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';
export const SET_LOG_OUT = 'SET_LOG_OUT';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const INITATE_BANK_AUTH_LOGIN = 'INITATE_BANK_AUTH_LOGIN';
export const SET_BANK_AUTH_LOGIN_DATA = 'SET_BANK_AUTH_LOGIN_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LIST_CONNECTED_USERS = 'SET_LIST_CONNECTED_USERS';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_BANKID_PROCCESS_STOP_FLAG = 'SET_BANKID_PROCCESS_STOP_FLAG';
export const INITIATE_HOSTED_BANK_AUTH_LOGIN = "INITIATE_HOSTED_BANK_AUTH_LOGIN";

