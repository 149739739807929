import { makeStyles } from "@material-ui/core/styles";
import settings from '../../template/settings';

const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      color: '#989898 !important',
      fontWeight: 200,
      fontSize: 16,
    },
    sysidlabel: {
      float: 'right',
      fontSize: 13,
      marginBottom: 10,
      fontWeight: 400,
    },
    customerNameLabel: {
      fontWeight: 400,
      fontSize: 20,
      marginBottom: 10,
      textTransform: 'uppercase',
      '& span': {
        textTransform: 'capitalize',
        color: settings.color2
      },
    },
    customerNrLabel: {
      marginBottom: 10,
      fontWeight: 400,
      fontSize: 20,
    },
    addressDataPanel: {
      margin: '16px 0px',
      '& p': {
        fontSize: 13,
        fontWeight: 400,
        margin: 0,
      }
    },
    titlesMargin: {
      margin: '15px 0px',
    },
    uppercaseValue:{
      fontWeight: 400,
      fontSize: 13,
      textTransform: 'uppercase',
    },
    normalizedValue:{
      fontWeight: 400,
      fontSize: 13,
    },
    uppercaseTitle:{
      textTransform: 'uppercase',
    },
    rowBraker:{
      widht:'100%',
      height:1,
      background:'#efefef',
      margin: '15px 0px',
    },
  }
  ));
  return styles();
}
export default theme;

