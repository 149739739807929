import {
  SET_LOADING_FILES,
  SET_ERROR_FILES,
  SET_FILE_LIST,
  SET_FILE_DATA, SET_SELECTED_XML,
} from '../actions/files/index';
const initialState = {
  loadingFiles: false,
  errorFiles: null,
  fileList: [],
  fileData: null,
  selectedXML: 0,
}
const FilesReducer = function (state = initialState, action) {
  const { type, payload, } = action;

  switch (type) {
    case SET_LOADING_FILES:
      return {
        ...state,
        loadingFiles: payload,
      }
    case SET_ERROR_FILES:
      return {
        ...state,
        loadingFiles: false,
        errorFiles: payload,
      }
    case SET_FILE_LIST:
      return {
        ...state,
        loadingFiles: false,
        errorFiles: null,
        fileList: payload,
      }
    case SET_FILE_DATA:
      return {
        ...state,
        loadingFiles: false,
        errorFiles: null,
        fileData: payload,
      }
    case SET_SELECTED_XML:
      return {
        ...state,
        selectedXML: payload
      }
    default:
      return state;
  }
}
export default FilesReducer;
