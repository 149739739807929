const custStatus = [
  {
    id:'0',
    text:'Props/Suppl',
  },
  {
    id:'1',
    text:'Only fee',
  },
  {
    id:'2',
    text:'All',
  },
]


export default custStatus;
