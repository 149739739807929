import React, { memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Typography, Breadcrumbs } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import {useLocation} from "react-router-dom";
import translate from '../../misc/applyTranslation'
import branding from '../../config/config.json'
const BreadCrumbsComponent = (props) => {
  const classes = theme();
  const {
    breadcrumbs
  } = props;
  let location = useLocation();

  const isHome = () => {
    let homeRegExp = /^\/[A-z]{2}\/?$/;
    return homeRegExp.test(location.pathname);
  }

  return (
    <div className={classes.breadcrumbsContainer}>
      <Typography className={classes.breadcrumbsTitle}>{isHome() ? branding.customer : translate('', breadcrumbs.pageTitle)}</Typography>
      <Breadcrumbs>
        {breadcrumbs.items.map((item, id) =>
          <Link key={id} color="inherit" className={classes.breadcrumbsItem} href={item.url}>
            {translate('', item.text)}
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
}
BreadCrumbsComponent.propTypes = {
  breadcrumbs: PropTypes.object.isRequired,
}
const mapStateToProps = store => ({
  breadcrumbs: store.state.breadcrumbs,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(BreadCrumbsComponent));
