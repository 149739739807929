import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { setErrorCustomer } from '../../redux/actions/customer';
import { setErrorInvoices } from '../../redux/actions/invoices';
import { setErrorLogin } from '../../redux/actions/login';
import { setErrorSuppliers } from '../../redux/actions/suppliers';
import { setErrorSupplierInvoices } from '../../redux/actions/supplierInvoices';
import { setErrorFiles } from '../../redux/actions/files';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const SnackBar = (props) => {
  const {
    setErrorCustomer,
    setErrorInvoices,
    setErrorLogin,
    setErrorSuppliers,
    setErrorSupplierInvoices,
    setErrorFiles,
    errorCustomer,
    errorInvoices,
    errorLogin,
    errorSuppliers,
    errorSuppliersInvoices,
    errorFiles,
  } = props;

  const [open, setOpen] = useState(errorCustomer || errorInvoices || errorLogin || errorSuppliers || errorSuppliersInvoices || errorFiles);
  useEffect(() => {
    if (errorCustomer) {
      setOpen({
        error: errorCustomer,
        func: setErrorCustomer,
      })
    }
    // eslint-disable-next-line
  }, [errorCustomer])
  useEffect(() => {
    if (errorInvoices) {
      setOpen({
        error: errorInvoices,
        func: setErrorInvoices,
      })
    }
    // eslint-disable-next-line
  }, [errorInvoices])
  useEffect(() => {
    if (errorLogin) {
      setOpen({
        error: errorLogin,
        func: setErrorLogin,
      })
    }
    // eslint-disable-next-line
  }, [errorLogin])
  useEffect(() => {
    if (errorSuppliers) {
      setOpen({
        error: errorSuppliers,
        func: setErrorSuppliers,
      })
    }
    // eslint-disable-next-line
  }, [errorSuppliers])
  useEffect(() => {
    if (errorSuppliersInvoices) {
      setOpen({
        error: errorSuppliersInvoices,
        func: setErrorSupplierInvoices,
      })
    }
    // eslint-disable-next-line
  }, [errorSuppliersInvoices])
  useEffect(() => {
    if (errorFiles) {
      setOpen({
        error: errorFiles,
        func: setErrorFiles,
      })
    }
    // eslint-disable-next-line
  }, [errorFiles])
  const handleClose = () => {
    open.func(null);
    setOpen(false);
  }
  return (
    <React.Fragment>
      {open &&
        <Snackbar open={!!open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {open.error.error}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
}

SnackBar.propTypes = {
  setErrorCustomer: PropTypes.func.isRequired,
  setErrorInvoices: PropTypes.func.isRequired,
  setErrorLogin: PropTypes.func.isRequired,
  setErrorSuppliers: PropTypes.func.isRequired,
  setErrorSupplierInvoices: PropTypes.func.isRequired,
  setErrorFiles: PropTypes.func.isRequired,
}

const mapStateToProps = store => ({
  errorCustomer: store.customer.error,
  errorInvoices: store.invoices.error,
  errorLogin: store.login.error,
  errorSuppliers: store.suppliers.error,
  errorSuppliersInvoices: store.supplierInvoices.error,
  errorFiles: store.files.errorFiles,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setErrorCustomer,
    setErrorInvoices, 
    setErrorLogin,
    setErrorSuppliers,
    setErrorSupplierInvoices,
    setErrorFiles,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(SnackBar));