import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({

    label: {
      width: 'calc(100% - 20px)',
      fontWeight: 400,
      fontSize: 14,
      margin: '15px 10px 5px 10px',
      border: 'none',
    }
  }));
  return styles();
}
export default theme;

