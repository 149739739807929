import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { InvoicesFees } from '../../../components/tables';
import { fetchFeesList, fetchCustomerTypes } from '../../../redux/actions/invoices';
const HandleFees = (props) => {
  const { fetchFeesList, fetchCustomerTypes, access_token, refresh_token, fees } = props;
  useEffect(() => {
    fetchList();
    fetchCustomerTypes({
      access_token: access_token,
      refresh_token: refresh_token,
    })
    // eslint-disable-next-line
  }, [])
  const fetchList = () => {
    fetchFeesList({
      access_token: access_token,
      refresh_token: refresh_token,
    })
  }
  return (
    <div style={{ paddingTop: 10 }}>
      <InvoicesFees data={fees} updateList={fetchList} />
    </div>
  );
}
HandleFees.propTypes = {
  fetchFeesList: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  fees: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  fees: store.invoices.fees,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchFeesList,
    fetchCustomerTypes
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(HandleFees));