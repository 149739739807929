import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    datepickerContainer: {
      width: 'calc(100% - 34px)',
      margin: '0px 10px',
      height: 20,
      background: 'white',
      borderRadius: 4,
      padding: '6px 12px 6px 0px',
      display: 'inline-flex',
      alignItems: 'center',
      '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      border: '1px solid #ddd',
      '& .label': {
        height: 34,
        background: settings.color3,
        color: settings.color6,
        display: 'flex',
        fontWeight: 400,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        padding: '0px 10px',
        borderRadius: '4px 0px 0px 4px',
      },
      '& .MuiFormControl-root': {
        width: 'calc(100%)',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px !important',
        color: '#555',
        padding: 0,
        paddingLeft: 6,
        height: 'auto',
        lineHeight: 'unset',
      },
      '& .MuiInputBase-root': {
        height: 'auto',
        lineHeight: 'unset',
      },
      '& .MuiInput-underline:before': {
        border: 'none !important',
        position: 'inherit',
      },
      '& .MuiInput-underline:after': {
        border: 'none !important'
      },
    },
  }));
  return styles();
}
export default theme;

