import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    valueEmpty: {
      padding: 10,
      color: '#989898',
      fontSize: 13,
    },
    invalid: {
      '& input': {
        border: '2px solid #ff524d !important',
      },
      '& input:focus': {
        border: '2px solid #ff524d',
        '-webkit-box-shadow': 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px #ff524d',
        boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px #ff524d',
      },
      '& .MuiSvgIcon-root': {
        border: '2px solid #ff524d !important',
        borderLeft: 'none !important',
        fill: '#ff524d',
        padding: '9px 15px',
      }
    },
    container: {
      display: 'inline-flex',
      width: '100%',
    },
    buttons: {
      cursor: 'pointer',
      border: `2px solid ${settings.color3}`,
      display: 'flex',
      justifyContent: 'center',
      alignitems: 'center',
      marginRight: 10,
      padding: '8px 15px',
      borderRadius: '0px 4px 4px 0px',
      fontSize: 14,
      fill: settings.color2,
    },
    textinput: {
      width: '100%',
      fontWeight: 400,
      fontSize: 14,
      marginLeft: 10,
      border: 'none',
      '& .Mui-disabled': {
        background: '#eee !important',
        cursor: 'not-allowed'
      },
      '& input': {
        height: 20,
        background: 'white',
        borderRadius: '4px 0px 0px 4px',
        padding: '6px 12px',
        '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
        border: '1px solid #ddd',
      },
      '& input:focus': {
        border: `1px solid ${settings.color3}`,
        '-webkit-box-shadow': `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${settings.color3}`,
        boxShadow: `inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 5px ${settings.color3}`,
      },
      '& ::before': {
        borderBottom: 'none !important',
      },
      '& ::after': {
        borderBottom: 'none !important',
      },
    },
    cartWrap: {
      marginLeft: 10,
      display: 'inline-flex',
      width: '100%',
      // '&:first-child': {
      //   backgroundColor: 'rgba(123, 123, 123, 0.1)',
      // },
      '&:not(:last-child)': {
        borderBottom: '1px solid #cccccc'
      },
      '& .namingGroup': {
        width: '100%',
        padding: 10,
        '& .topWrap': {
          display: 'flex',
          justifyContent: 'space-between',
          '& .name': {
            color: settings.color2,
            fontSize: 13,
          },
          '& .number': {
            color: settings.color2,
            fontSize: 10,
          },
        },
        '& .bottomWrap': {
          margin: '15px 0px',
          '& .address': {
            color: '#989898',
            fontSize: 13,
          },
        },
      },
      '& .boxWrap': {
        cursor: 'pointer',
        width: 140,
        minWidth: 140,
        margin: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        '& .status': {
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 14,
          textTransform: 'uppercase',
          padding: 5,
        },
        '& .status_connected': {
          background: '#5ba26f',
        },
        '& .status_not_connected': {
          background: settings.color2,
        },
        '& .text': {
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 14,
          padding: 5,
        }
      },
      '& .boxWrap_connected': {
        borderColor: '#5ba26f',
        '&:hover': {
          backgroundColor: 'rgba(91, 162, 111,0.2);'
        },
        '& .text': {
          color: '#5ba26f',
        }
      },
      '& .boxWrap_not_connected': {
        borderColor: settings.color3,
        '&:hover': {
          backgroundColor: settings.rgba,
        },
        '& .text': {
          color: settings.color2,
        }
      },
    },
    selectedWrap: {
      width: 'calc(100% - 22px)',
      border: '1px solid #ddd',
      borderTop: 'unset',
      marginTop: '-3',
    }
  }));
  return styles();
}
export default theme;

