import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({

    borderBlue: {
      border: `2px solid ${settings.color3} !important`,
      '& svg': {
        fill: settings.color2
      }
    },
    red: {
      background: '#ff524d !important',
      border: '2px solid #ff524d !important',
      '& svg': {
        fill: '#fff'
      }
    },
    container: {
      minHeight: 34,
      cursor: 'pointer',
      width: 'calc(100% - 24px)',
      fontWeight: 400,
      fontSize: 14,
      margin: '0px 10px',
      background: 'white',
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '-webkit-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      '-moz-box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.12)',
      border: '2px solid transparent',
      height: 22,
      padding: '4px 0px',
      '& .red ': {
        background: 'red',
        fill: 'white'
      },
      '&:hover , .borderBlue': {
        border: `2px solid ${settings.color3}`,
      },
      '& svg': {
        fontSize: 15,
      },
    },
    disabled: {
      cursor: 'not-allowed !important',
      pointerEvents: 'inherit !important'
    },
  }));
  return styles();
}
export default theme;

