import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      padding: 20,
      '& .title': {
        color: '#989898',
        fontWeight: 500,
        fontSize: 20,
        // opacity: 0.8,
        marginBottom: 20,
      },
      '& .text': {
        color: '#989898',
        fontWeight: 500,
        fontSize: 14,
        marginBottom: 20,
        '& span': {
          fontWeight: 300,
        }
      }
    }
  }));
  return styles();
}
export default theme;

