import React, { Fragment, memo, useState } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
import { BorderButton } from '../../../components/buttons';
import { BorderSelect } from '../../../components/inputs';
import {
  dismissSupplierInvoice,
  deleteSupplierInvoice,
} from '../../../redux/actions/supplierInvoices';
import selectMockData from '../../../misc/mockDataDismissReason';
import { useParams } from 'react-router-dom';
const Dismiss = (props) => {
  const params = useParams();
  const classes = theme();
  const {
    access_token,
    refresh_token,
    dismissSupplierInvoice,
    deleteSupplierInvoice,
  } = props;
  const [dismissState, setDismissState] = useState()
  const [missingReason, setMissingReason] = useState()
  const handleDissmissInvoice = () => {
    if (dismissState) {
      dismissSupplierInvoice({
        access_token,
        refresh_token,
        id: params.id,
        reason: selectMockData.find(item => item.id === dismissState).text,
      })
      setDismissState();
      setMissingReason();
    } else {
      setMissingReason(true);
    }
  }
  const handleDeleteInvoice = () => {
    if (dismissState) {
      deleteSupplierInvoice({
        access_token,
        refresh_token,
        id: params.id,
      })
      setDismissState();
      setMissingReason();
    } else {
      setMissingReason(true);
    }
  }
  return (
    <Fragment>
      <div className={classes.title}>{translate('supplier-invoices/detail', 'dismiss_panel_title')}</div>
      <div className={classes.text}>{translate('supplier-invoices/detail', 'dismiss_panel_dissmiss_invoice_label')}</div>
      <div className={classes.group}>
        <div className={classes.input}>
          <BorderSelect
            selected={dismissState}
            items={selectMockData}
            onChange={(e) => {
              setDismissState(e)
              setMissingReason();
            }
            }
            invalid={!!missingReason}
          />
        </div>
      </div>
      <div className={classes.group}>
        <div className={classes.action}>
          <BorderButton onClick={handleDissmissInvoice} text={translate('', 'button_dismiss')} />
        </div>
        <div className={classes.action}>
          <BorderButton warning onClick={handleDeleteInvoice} text={translate('', 'button_remove')} />
        </div>
      </div>
    </Fragment >
  );
}
Dismiss.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    dismissSupplierInvoice,
    deleteSupplierInvoice,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(Dismiss));