import React, {memo, useEffect, useState} from 'react';
import theme from './style';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {InvoicesBatchTitel as TitelTable, InvoicesBatchPyramid as PyramidTable} from '../../../components/tables';
import {
  fetchPendingBatches,
  fetchInvoicedBatches,
  deletePendingBatch,
  approvePendingBatch,
  fetchBatchPDF,
  setInvoicedBatchesFilter
} from '../../../redux/actions/invoices';
import translate from '../../../misc/applyTranslation';
import moment from 'moment';
import ConfirmationModal from '../../../components/confirmationWindow';
import Pagination from "../../../components/Pagination";

const HandleBatch = (props) => {
  const [holdTempVals, setHoldTempVals] = useState();
  const classes = theme();
  const {
    fetchPendingBatches,
    fetchInvoicedBatches,
    deletePendingBatch,
    approvePendingBatch,
    fetchBatchPDF,
    access_token,
    refresh_token,
    pendingBatches,
    invoicedBatches,
    page,
    totalPages,
    setInvoicedBatchesFilter
  } = props;
  useEffect(() => {
    fetchPendingBatches({
      access_token,
      refresh_token,
    })
    // eslint-disable-next-line
  }, [])
  const handleFetchInvoicesBatches = () => {
    fetchInvoicedBatches({
      access_token,
      refresh_token,
      page: page
    })
  }
  const handleDelete = (id) => {
    deletePendingBatch({
      access_token,
      refresh_token,
      id,
    })
  }
  const handleApprove = (id) => {
    approvePendingBatch({
      access_token,
      refresh_token,
      id,
      page
    })
  }
  const handlePDF = (id) => {
    fetchBatchPDF({
      access_token,
      refresh_token,
      id,
    }).then(res => {
      if (res) {
        var url = window.URL.createObjectURL(res);
        var a = document.createElement('a');
        a.href = url;
        let item = invoicedBatches.find(item => item.batch_id === id);
        a.download = `faktura_${item.name}_${id}_${moment().format('YYYYMMDD')}.XML`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
  }
  useEffect(
    () => {
      handleFetchInvoicesBatches();
      const id = setInterval(handleFetchInvoicesBatches, 60000);
      return () => clearInterval(id);
    },
    // eslint-disable-next-line
    [page]
  );
  const handleClose = () => {
    setHoldTempVals()
  }
  const handleConfirm = () => {
    const {id, type} = holdTempVals;
    if (type === 'approve') {
      handleApprove(id)
    } else {
      handleDelete(id)
    }
    handleClose();
  }
  const handleAproveTrigger = (id) => {
    setHoldTempVals({
      id,
      type: 'approve',
    })
  }
  const handleRejectTrigger = (id) => {
    setHoldTempVals({
      id,
      type: 'reject',
    })
  }
  const handlePagination = value => {
    setInvoicedBatchesFilter({
      key: 'page',
      value: value
    })
  }

  return (
    <>
      <div className={classes.paperPadding}>
        <ConfirmationModal open={!!holdTempVals} onAbort={handleClose} onAccept={handleConfirm}/>
        <TitelTable title={translate('invoices', 'batch_pending_batch_title')} data={pendingBatches}
                    handleApprove={handleAproveTrigger} handleDelete={handleRejectTrigger}/>
        <PyramidTable title={translate('invoices', 'batch_latest_table_title')} data={invoicedBatches}
                      handlePDF={handlePDF}/>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Pagination page={page} totalPages={totalPages} onClick={handlePagination}/>
      </div>
    </>
  );
}
HandleBatch.propTypes = {
  fetchPendingBatches: PropTypes.func.isRequired,
  fetchInvoicedBatches: PropTypes.func.isRequired,
  deletePendingBatch: PropTypes.func.isRequired,
  approvePendingBatch: PropTypes.func.isRequired,
  fetchBatchPDF: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  pendingBatches: PropTypes.array.isRequired,
  invoicedBatches: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setInvoicedBatchesFilter: PropTypes.func.isRequired
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  pendingBatches: store.invoices.pendingBatches,
  invoicedBatches: store.invoices.invoicedBatches.data,
  page: store.invoices.invoicedBatchesFilter.page,
  totalPages: store.invoices.invoicedBatches.totalPages
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchPendingBatches,
    fetchInvoicedBatches,
    deletePendingBatch,
    approvePendingBatch,
    fetchBatchPDF,
    setInvoicedBatchesFilter
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(HandleBatch));
