import React, { Fragment, memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
import clsx from 'clsx';
const CustomerDetails = (props) => {
  const classes = theme();
  const {
    customer_data,
    facilities
  } = props;

  return (
    <Fragment>
      <div className={classes.titleGray}>{translate('supplier-invoices/detail', 'customer_panel_customer_title')}</div>
      <div className={classes.text}>{customer_data.name}</div>
      <div className={classes.text}>{customer_data.address1}</div>
      <div className={classes.text}>{customer_data.post_code} {customer_data.city}</div>
      <div className={classes.text}>{customer_data.email1}</div>
      <div className={classes.text}>{customer_data.tel1}</div>
      {!!facilities.length &&
        <Fragment>
          <div className={clsx(classes.text, classes.bold)}>{translate('supplier-invoices/detail', 'customer_panel_supplier_invoices_yearly_kw_label')}</div>
          {facilities.map(facility =>
            <div className={classes.text}>{`${facility.facility} : ${facility.yearly_kw}`} kWh</div>
          )}
        </Fragment>
      }
    </Fragment >
  );
}
CustomerDetails.propTypes = {
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  customer_data: store.supplierInvoices.supplierInvoiceDetail.customer_data,
  facilities: store.supplierInvoices.supplierInvoiceDetail.facilities,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerDetails));