import React, { memo, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BackButton } from '../../components/buttons';
import { CustomerInboundInvoices as TableInbound, CustomerOutboundInvoices as TableOutbound } from '../../components/tables';
import { setInvoiceViewId, } from '../../redux/actions/customer';
import {
  fetchCustomerInvoiceDetail,
  prolongDueDate,
} from '../../redux/actions/customer/customerDetail';
import { downloadSupplierInvoicePDF, } from '../../redux/actions/supplierInvoices';
import translate from '../../misc/applyTranslation';
import moment from 'moment';
const CustomerInvoiceList = (props) => {
  const classes = theme();
  const {
    setInvoiceViewId,
    fetchCustomerInvoiceDetail,
    access_token,
    refresh_token,
    customerInvoicesDetailList,
    selectedInvoiceViewId,
    name, name2,
    prolongDueDate,
    downloadSupplierInvoicePDF,
  } = props;
  useEffect(() => {
    fetchCustomerInvoiceDetail({
      access_token,
      refresh_token,
      id: selectedInvoiceViewId
    })
    // eslint-disable-next-line
  }, [])
  const handleProlong = e => {
    prolongDueDate({
      access_token,
      refresh_token,
      id: selectedInvoiceViewId,
      date: moment(e).format('YYYY-MM-DD')
    })
  }
  const handlePDFDownload = (id, type) => {
    downloadSupplierInvoicePDF({
      access_token,
      refresh_token,
      id: id,
      type
    }).then(res => {
      try {
        let blob = new Blob([res], { type: "application/pdf" });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      } catch (error) {

      }
    })
  }
  return (
    <React.Fragment>
      <div className={classes.customerInvoiceDetailContainer}>
        <div>
          <BackButton onClick={e => setInvoiceViewId(null)} text={translate('', 'button_back')} />
        </div>
      </div>
      {customerInvoicesDetailList &&
        <React.Fragment>
          <div className={classes.fixMargins}>
            <TableOutbound elevation={4} onProlong={handleProlong} title={name + ' ' + name2} id={selectedInvoiceViewId} data={[customerInvoicesDetailList.invoice]} downloadPDF={handlePDFDownload} />
          </div>
          <div className={classes.fixMargins}>
            <TableInbound elevation={4} title={translate('customers/detail', 'supplier_invoices_detail_title')} invoice={customerInvoicesDetailList.invoice} data={customerInvoicesDetailList.invoice_rows} downloadPDF={handlePDFDownload} />
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}
CustomerInvoiceList.propTypes = {
  setInvoiceViewId: PropTypes.func.isRequired,
  fetchCustomerInvoiceDetail: PropTypes.func.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  customerInvoicesDetailList: store.customer.customerInvoicesDetailList,
  selectedInvoiceViewId: store.customer.selectedInvoiceViewId,
  name: store.customer.customerData.name,
  name2: store.customer.customerData.name2
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setInvoiceViewId,
    fetchCustomerInvoiceDetail,
    prolongDueDate,
    downloadSupplierInvoicePDF
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerInvoiceList));
