import React, { memo, useEffect } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BorderSelect } from '../../components/inputs';
import { CustomerLatestInvoices as Table } from '../../components/tables';
import { setInvoiceViewId } from '../../redux/actions/customer';
import { setInvoicesListFilter, fetchInvoicesList } from '../../redux/actions/customer/customerDetail';
import { useParams } from 'react-router-dom';
import MockDataYears from '../../misc/mockDataYears';
import translate from '../../misc/applyTranslation'
const CustomerInvoiceList = (props) => {
  const classes = theme();
  const params = useParams();
  const {
    setInvoiceViewId,
    setInvoicesListFilter,
    fetchInvoicesList,
    invoicesList,
    year,
    access_token,
    refresh_token,
    name, name2,
  } = props;

  const handleTableSelect = (id) => {
    setInvoiceViewId(id);
  }

  useEffect(() => {
    fetchInvoicesList({
      customer_id: params.id,
      year: year,
      access_token: access_token,
      refresh_token: refresh_token,
    })
    // eslint-disable-next-line
  }, [year])


  return (
    <Paper elevation={4} className={classes.customerInvoiceListContainer}>
      <div className={classes.customerInvoiceListHeader} >
        <Typography className={'forceUppercase'}>{translate('customers/detail', 'invoices_table_title_customer')} <span>{name + ' ' + name2}</span></Typography>
        <Grid container>
          <Grid item xs={12} sm={8} md={9}>
            <Typography>{translate('customers/detail', 'invoices_table_title')}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <BorderSelect
              selected={year}
              onChange={(e) => setInvoicesListFilter(e)}
              items={MockDataYears}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ width: 'calc(100% + 20px)' }}>
        <Table elevation={0} data={invoicesList} onClick={(id) => handleTableSelect(id)} />
      </div>
    </Paper >
  );
}
CustomerInvoiceList.propTypes = {
  setInvoiceViewId: PropTypes.func.isRequired,
  invoicesList: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,

}
const mapStateToProps = store => ({
  invoicesList: store.customer.invoicesList.data,
  year: store.customer.invoicesListFilter.year,
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  name: store.customer.customerData.name,
  name2: store.customer.customerData.name2,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setInvoiceViewId,
    setInvoicesListFilter,
    fetchInvoicesList
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerInvoiceList));
