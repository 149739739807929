import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    titleGray: {
      fontSize: 20,
      textTransform: 'uppercase',
      fontWeight: 'normal',
      marginBottom: 10,
      '& span': {
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'capitalize',
      }
    },
    text: {
      fontSize: 13,
      fontWeight: 400,
    },
    bold: {
      fontWeight: 'bold'
    }
  }));
  return styles();
}
export default theme;

