
import React, { memo, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { NewButton, RefreshButton, SearchButton } from '../../components/buttons'
import { CustomersList as Table } from '../../components/tables'
import { TextInput } from '../../components/inputs'
import Pagination from '../../components/Pagination'
import { Grid } from '@material-ui/core';
import { useHistory, withRouter } from "react-router-dom";
import { setCustomerListFilter, fetchCustomerList, clearCustomerFilter } from '../../redux/actions/customer/customerList';
import { clearCustomerData } from '../../redux/actions/customer/customerDetail';
import translate from '../../misc/applyTranslation';
const CustomerListView = (props) => {
  const classes = theme();
  const history = useHistory();
  const {
    clearCustomerData,
    setCustomerListFilter,
    fetchCustomerList,
    clearCustomerFilter,
    search,
    search_invoice,
    page,
    limit,
    data,
    totalPages,
    access_token,
    refresh_token
  } = props;

  const handleFilter = (value, key) => {
    setCustomerListFilter({
      key: key,
      value: value,
    })
  }
  const handlePagination = (value) => {
    setCustomerListFilter({
      key: 'page',
      value: value,
    })
  }
  const fetchData = () => {
    fetchCustomerList({
      access_token: access_token,
      refresh_token: refresh_token,
      search: search,
      search_invoice: search_invoice,
      page: page,
      limit: limit
    });
  }
  const handleSearch = (e) => {
    try { e.preventDefault() } catch (error) { }
    page === 1 ? fetchData() : handlePagination(1)
  }
  const handleClearFilter = () => {
    clearCustomerFilter({
      access_token: access_token,
      refresh_token: refresh_token,
    });
  }
  useEffect(() => {
    clearCustomerData();
    fetchData();
    // eslint-disable-next-line
  }, [page])
  return (
    <React.Fragment>
      <form onSubmit={handleSearch}>
        <Grid container className={classes.fixInputMargins}>
          <Grid item xs={12} sm={3}><TextInput placeholder={translate('customers', 'search_label_customer')} value={search} onChange={(e) => handleFilter(e.target.value, 'search')} /></Grid>
          <Grid item xs={12} sm={3}><TextInput placeholder={translate('customers', 'search_label_invoice')} value={search_invoice} onChange={(e) => handleFilter(e.target.value, 'search_invoice')} /></Grid>
          <Grid item xs={12} sm={3}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <SearchButton type='submit'//onClick={handleSearch} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RefreshButton onClick={handleClearFilter} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}><NewButton text={translate('customers', 'button_add_customer')} onClick={() => history.push(`customers/add-customer`)} /></Grid>
        </Grid>
      </form>
      <Table data={data} />
      <Grid container className={classes.fixInputMargins} >
        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination page={page} totalPages={totalPages} onClick={handlePagination} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
CustomerListView.propTypes = {
  setCustomerListFilter: PropTypes.func.isRequired,
  fetchCustomerList: PropTypes.func.isRequired,
  clearCustomerFilter: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  search_invoice: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
}
const mapStateToProps = store => ({
  search: store.customer.customerListFilter.search,
  search_invoice: store.customer.customerListFilter.search_invoice,
  page: store.customer.customerListFilter.page,
  limit: store.customer.customerListFilter.limit,
  data: store.customer.customerList.data,
  totalPages: store.customer.customerList.totalPages,
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    setCustomerListFilter,
    fetchCustomerList,
    clearCustomerFilter,
    clearCustomerData,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));
