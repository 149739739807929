
import React, { memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import translate from '../../misc/applyTranslation';
const CustomerListView = (props) => {
  const classes = theme();
  const { userData } = props;
  const {
    name,
    name2,
  } = userData;
  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <div className='titleHome'>{translate('home', 'welcome')}, <span>{name} {name2}</span></div>
        <div className='logo' />
      </Paper>
    </React.Fragment>
  )
}

const mapStateToProps = store => ({
  userData: store.login.userData,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(CustomerListView)));
