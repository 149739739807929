const AutoGiroStatus = [
  {
    id:'0',
    text:'Disable Account',
  },
  {
    id:'1',
    text:'Enable Account',
  },
]


export default AutoGiroStatus;
