import React, {memo} from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import translate from '../../../misc/applyTranslation';
import toMoney from '../../../misc/serializeToMoney'

const CustomerFees = (props) => {
  const classes = theme();
  const {
    onClick,
    onEdit,
    onDelete,
    data,
  } = props;
  return (
    <React.Fragment>
      <TableContainer component={'div'} className={classes.container}>
        <Table className={`${classes.table} `} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{translate('edit-fee', 'table_header_invoice_row')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_account_nr')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_vat')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_amount')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_fee')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_fee_vat')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_edit')}</TableCell>
              <TableCell>{translate('edit-fee', 'table_header_delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row) => (
              <TableRow key={row.id} onClick={() => {
                if (onClick) {
                  onClick(row.id)
                }
              }}>
                <TableCell>{row.inv_row_text}</TableCell>
                <TableCell>{row.accountnumber}</TableCell>
                <TableCell>{toMoney(row.inv_row_vat)}</TableCell>
                <TableCell>{toMoney(row.inv_row_amount)}</TableCell>
                <TableCell>{toMoney(row.inv_fee)}</TableCell>
                <TableCell>{toMoney(row.inv_fee_vat)}</TableCell>
                <TableCell align='center'>
                  {(!row.propsfile_id && !row.props_invoice_id) &&
                  <EditIcon style={(!row.propsfile_id && !row.props_invoice_id) ? {cursor: 'pointer'} : {}}
                            onClick={() => {
                              if (!row.propsfile_id && !row.props_invoice_id)
                                onEdit(row)
                            }}/>
                  }
                </TableCell>
                <TableCell align='center'>
                  {(!row.propsfile_id && !row.props_invoice_id) &&
                  <CloseIcon style={(!row.propsfile_id && !row.props_invoice_id) ? {
                    cursor: 'pointer',
                    fill: 'red'
                  } : {fill: "red"}}
                             onClick={() => {
                               if (!row.propsfile_id && !row.props_invoice_id)
                                 onDelete(row.id)
                             }}
                  />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
CustomerFees.propTypes = {
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.array.isRequired,
}

export default memo(CustomerFees);
