import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({

    container: {
      width: 'calc(100% - 20px)',
      fontWeight: 400,
      fontSize: 14,
      margin: '10px 10px',
      border: 'none',
      display: 'inline-flex',
      '& span': {
        fontSize: 14,
      },
      '& svg': {
        fontSize: 16,
        color: settings.color2,
      },
      '& .MuiButtonBase-root': {
        padding: '9px 9px 9px 0px'
      }
    }
  }));
  return styles();
}
export default theme;

