import React, { memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import 
const TemplateLogin = (props) => {
  const classes = theme();

  const {
    children
  } = props;

  return (
    <div className={classes.root}>
      <div className='container'>
        <div className='logo' />
        <div className='body' >
          {children}
        </div>
      </div>
    </div>
  );
}
TemplateLogin.propTypes = {
}
const mapStateToProps = store => ({
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(TemplateLogin));