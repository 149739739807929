import translate from './applyTranslation';
const server =true? window.location.origin : 'https://staging.cheap.cust.commerz.se';
const APICall = async (params) => {
  let { verb, url, body, access_token } = params;
  var myHeaders = new Headers();
  if (access_token) {
    myHeaders.append("Authorization", `Bearer ${access_token}`);
  }
  var requestOptions = {
    method: verb || 'get',
    headers: myHeaders,
    body: body,
  };

  let requestURL = `${server}/api/${url}`
  return await fetch(requestURL, requestOptions)
    .then(async response => {
      let handledResponse = await response.json();
      if (response.status < 400) {
        return handledResponse;
      } else {
        let err = {
          error: handledResponse.message || handledResponse.error || response.statusText,
          err: JSON.stringify(handledResponse),
        }
        throw err;
      }
    })
    .then(res => {
      if (res.error) {
        throw (res);
      }
      return res;
    })
    .catch(error => {
      let err = {
        error: error.error || translate('', 'generic_api_call_error'),
        err: JSON.stringify(error),
      }
      throw err;
    });
}
export const APIDownload = async (params) => {
  let { verb, url, body, access_token } = params;
  var myHeaders = new Headers();
  if (access_token) {
    myHeaders.append("Authorization", `Bearer ${access_token}`);
  }
  var requestOptions = {
    method: verb || 'get',
    headers: myHeaders,
    body: body,
    redirect: 'follow'
  };

  let requestURL = `${server}/api/${url}`
  return await fetch(requestURL, requestOptions)
    .then(async response => {
      let handledResponse = await response.blob();
      if (response.status < 400) {
        return handledResponse;
      } else {
        let err = {
          error: handledResponse.message || handledResponse.error || response.statusText,
          err: JSON.stringify(handledResponse),
        }
        throw err;
      }
    })
    .then(res => {
      if (res.error) {
        throw (res);
      }
      return res;
    })
    .catch(error => {
      let err = {
        error: error.error || translate('', 'generic_api_call_error'),
        err: JSON.stringify(error),
      }
      throw err;
    });
}

export default APICall;

