import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    fixMargin: {
      margin: '0px -10px 10px -10px',
      width: 'calc(100% + 20px)',
    },
    fixMarginTable: {
      // margin: '0px -10px 10px -10px',
      width: 'calc(100% + 20px)',
    },
    
    titleContainer: {
      marginBottom:10,
      padding: '10px 0px',
      color: '#989898',
      '& .title': {
        padding: 10,
        fontSize: 20,
        fontWeight: 400,
      },
      '& .text': {
        padding: 10,
        fontSize: 13,
        fontWeight: 400,
      }
    }
  }));
  return styles();
}
export default theme;

