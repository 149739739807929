import React, { memo, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Pagination from '../../components/Pagination';
import { TextInput } from '../../components/inputs';
import { NewButton, SearchButton, RefreshButton } from '../../components/buttons';
import { CustomerConnectionSupplier as ConnectionTable, CustomerSupplierList as SupplierList } from '../../components/tables';
import {
  fetchConnectionFeesList,
  setConnectionSuppliersListFilter,
  fetchConnectionSuppliersList,
  clearSupplierFilter
} from '../../redux/actions/customer/customerConnection';
import { useHistory, useParams } from 'react-router-dom';
import translate from '../../misc/applyTranslation';
const CustomerInvoiceList = (props) => {
  const classes = theme();
  const history = useHistory();
  const params = useParams();
  const {
    fetchConnectionFeesList,
    setConnectionSuppliersListFilter,
    fetchConnectionSuppliersList,
    access_token,
    refresh_token,
    fees,
    name,
    page,
    limit,
    supplierList,
    totalPages,
    clearSupplierFilter,
  } = props;
  const { id } = params
  const handleNavigation = (supplier) => {
    history.push(`${id}/supplier-connection/${supplier}`)
  }
  useEffect(() => {
    fetchConnectionFeesList({
      access_token: access_token,
      refresh_token: refresh_token,
      customer_id: params.id,
    });
    // eslint-disable-next-line
  }, [])
  // eslint-disable-next-line
  useEffect(() => { fetchSuppliers(); }, [page])

  const fetchSuppliers = () => {
    fetchConnectionSuppliersList({
      access_token: access_token,
      refresh_token: refresh_token,
      name: name,
      page: page,
      limit: limit
    });
  }

  return (
    <React.Fragment>
      <div className={classes.editSupplierConnectionAddContainer}>
        <div>
          <NewButton onClick={e => handleNavigation('new')} text={translate('customers/detail', 'add_supplier_to_customer')} />
        </div>
      </div>
      <div className={classes.fixMargins}>
        <ConnectionTable data={fees} elevation={4} onClick={e => handleNavigation(e)} />
      </div>
      <div className={classes.paginationContainer}>
        <Grid container className={classes.fixInputMargins}>
          <Grid item xs={12} sm={3}><TextInput value={name} placeholder={translate('customers/detail', 'supplier_table_supplier_header')}
            onChange={(e) =>
              setConnectionSuppliersListFilter({
                value: e.target.value,
                key: 'name'
              })}
          /></Grid>
          <Grid item xs={12} sm={3}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <SearchButton onClick={fetchSuppliers} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RefreshButton onClick={() => clearSupplierFilter(access_token)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.fixMargins}>
        <SupplierList elevation={4} data={supplierList} onClick={e => handleNavigation(`new?sn=${e}`)} />
      </div>
      <div className={classes.paginationContainer}>
        <Pagination page={page} totalPages={totalPages} onClick={(e) =>
          setConnectionSuppliersListFilter({
            value: e,
            key: 'page'
          })} />
      </div>
    </React.Fragment>
  );
}
CustomerInvoiceList.propTypes = {
  fetchConnectionFeesList: PropTypes.func.isRequired,
  setConnectionSuppliersListFilter: PropTypes.func.isRequired,
  fetchConnectionSuppliersList: PropTypes.func.isRequired,
  clearSupplierFilter: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  supplierList: PropTypes.array.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  fees: store.customer.fees,
  name: store.customer.connectionSupplierFilter.name,
  page: store.customer.connectionSupplierFilter.page,
  limit: store.customer.connectionSupplierFilter.limit,
  supplierList: store.customer.connectionSupplierList.data,
  totalPages: store.customer.connectionSupplierList.totalPages,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchConnectionFeesList,
    setConnectionSuppliersListFilter,
    fetchConnectionSuppliersList,
    clearSupplierFilter
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerInvoiceList));