import {
  SET_FORM_FIELDS,
  SET_LOADING_LOGIN,
  SET_ERROR_LOGIN,
  SET_LOGIN_DATA,
  SET_LOG_OUT,
  SET_USER_TYPE,
  INITATE_BANK_AUTH_LOGIN,
  SET_BANK_AUTH_LOGIN_DATA,
  SET_USER_DATA,
  SET_LIST_CONNECTED_USERS,
  SET_SELECTED_CUSTOMER,
  SET_BANKID_PROCCESS_STOP_FLAG
} from '../actions/login/types';
const initialState = {
  loading: false,
  error: null,
  authed: false,
  user_admin: true,
  email: '',
  password: '',
  ssn: '',
  code: '',
  token_type: '',
  expires_in: null,
  login_date: null,
  refresh_token: '',
  access_token: '',
  bankAuthInit: false,
  userData: {},
  connectedCustomers: [],
  selectedCustomer: null,
  abortedBankIdProccess: false,
}
const LoginReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_LOADING_LOGIN:
      return {
        ...state,
        loading: payload,
        error: null,
      }
    case SET_ERROR_LOGIN:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_FORM_FIELDS:
      return {
        ...state,
        [payload.key]: payload.value,
      }
    case SET_LOGIN_DATA:
      return {
        ...state,
        authed: true,
        password: '',
        code: '',
        ssn: '',
        email: '',
        bankAuthInit: false,
        loading: false,
        error: null,
        ...payload,
      }
    case INITATE_BANK_AUTH_LOGIN:
      return {
        ...state,
        bankAuthInit: payload.sessionId,
        loading: false,
        error: null,
        abortedBankIdProccess: false,
      }
    case SET_BANK_AUTH_LOGIN_DATA:
      return {
        ...state,
        authed: true,
        password: '',
        code: '',
        ssn: '',
        email: '',
        bankAuthInit: false,
        loading: false,
        error: null,
        ...payload,
      }
    case SET_LOG_OUT:
      return {
        ...state,
        authed: false,
        loading: false,
        userData: {},
        error: null,
      }
    case SET_USER_TYPE:
      return {
        ...state,
        user_admin: !state.user_admin,
      }
    case SET_USER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        userData: payload,
      }
    case SET_LIST_CONNECTED_USERS:
      return {
        ...state,
        loading: false,
        error: null,
        connectedCustomers: payload.data,
        selectedCustomer: payload.selected,
      }
    case SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: payload,
      }
    case SET_BANKID_PROCCESS_STOP_FLAG:
      return {
        ...state,
        abortedBankIdProccess: true,
      }
    default:
      return state;
  }
}
export default LoginReducer;