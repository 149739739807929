import React, { Fragment, useState, memo, useEffect } from 'react';
import theme from './style'
import { TextField, Typography, Popover } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Cart from './cart';
import { fetchCustomerListForConnectionSearch } from '../../../redux/actions/supplierInvoices'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import translate from '../../../misc/applyTranslation';
const CustomerConnectionSearch = (props) => {
  const classes = theme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState('');
  const [customerID, setcustomerID] = useState();
  const {
    fetchCustomerListForConnectionSearch,
    access_token,
    refresh_token,
    supplier_id,
    placeholder,
    customersSearchList,
    value,
    onChange,
    invalid
  } = props;


  const handleChange = (e) => {
    setText(e.target.value);
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSearch = () => {
    return fetchCustomerListForConnectionSearch({
      access_token,
      refresh_token,
      supplier_id,
      search: text,
    })
  }
  const handleClick = (e) => {
    try { e.preventDefault() } catch (error) { }
    if (text.length > 0) {
      handleSearch().then(done => {
        setOpen(true)
      })
    } else {
      setOpen(true)
    }
  }
  const handleSelectCustomer = (id) => {
    if (onChange) {
      setOpen(false)
      onChange(id);
      setcustomerID(id);
      // setText(customersSearchList.find(item => item.id === id).name)
    }
  }
  return (
    <Fragment>
      <form onSubmit={handleClick}>
        <div
          id='customerConnectionSearch'
          className={classes.container}
          className={`${classes.container} ${invalid ? classes.invalid : ''} `}
        >
          <TextField
            id='inputCustomerConnectionSearch'
            onChange={handleChange}
            className={classes.textinput}
            placeholder={placeholder}
            value={text}
          />
          <SearchIcon
            type='submit'
            onClick={handleClick}
            className={classes.buttons}
          />
        </div>
        {open &&
          <Popover
            open={open}
            anchorEl={document.getElementById('inputCustomerConnectionSearch')}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

          >
            <div
              style={{
                width: document.getElementById('customerConnectionSearch').offsetWidth - 20
              }}>

              {(text.length === 0 || customersSearchList.length === 0) &&
                <div className={classes.valueEmpty} > {translate('supplier-invoices/detail', 'customer_connect_no_customer_found')}</div>
              }
              {text.length > 0 && customersSearchList.map(customer =>
                <Cart {...customer} onClick={handleSelectCustomer} />
              )}
            </div>
          </Popover>
        }
      </form>
      {customerID &&
        < Cart {...customersSearchList.find(item => item.id === customerID)} showOnly={true} />
      }
    </Fragment>

  );
}
CustomerConnectionSearch.propTypes = {
  fetchCustomerListForConnectionSearch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  supplier_id: store.supplierInvoices.supplierInvoiceDetail.supplier_data.id,
  customersSearchList: store.supplierInvoices.customersSearchList,

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchCustomerListForConnectionSearch,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerConnectionSearch));