import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      width: 'calc(100% - 20px)',
      marginBottom: 20,
      padding: 10,
    },
    buttonContainer: {
      display: 'inline-flex',
      width: 'calc(100% + 20px)',
      marginLeft: -10,
      '& button': {
        width: 160,
      }
    },
    table: {
      minWidth: 650,
      width: 'calc(100%)',
      '& .icon': {
        padding: '6px 24px 6px 16px !important',
        '& svg': {
          marginTop: 5
        }
      },
      '& .rowEdit': {
        '& td': { padding: '6px 0px' },
      },

      '& .MuiTableCell-head': {
        color: '#989898',
        fontSize: 13,
        fontWeight: 400,
        borderBottomWidth: 2,
      },
      '& .MuiTableCell-body': {
        color: '#989898',
        fontSize: '13px !important',
        fontWeight: 400,
        '& svg': {
          fontSize: '13px !important',
          fill: '#989898'
        }
      },
      '& .pointer': {
        cursor: 'pointer'
      }
    },

  }));
  return styles();
}
export default theme;

