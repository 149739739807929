import APICall from '../../../misc/APICall';
import { setLoadingCustomer, setErrorCustomer } from './index';
import { renewToken } from '../login'
export const SET_CUSTOMER_LIST_FILTER = 'SET_CUSTOMER_LIST_FILTER';
export const SET_CUSTOMER_LIST_DATA = 'SET_CUSTOMER_LIST_DATA';
export const CLEAR_CUSTOMER_LIST_FILTER = 'CLEAR_CUSTOMER_LIST_FILTER';

export const setCustomerListFilter = (payload) => {
  return {
    type: SET_CUSTOMER_LIST_FILTER,
    payload: payload
  }
}
export const clearCustomerFilter = (payload) => {
  let { access_token, refresh_token } = payload;
  return async dispatch => {
    dispatch({
      type: CLEAR_CUSTOMER_LIST_FILTER,
    })
    dispatch(
      fetchCustomerList({
        page: 1,
        limit: 10,
        search: '',
        search_invoice: '',
        access_token: access_token,
        refresh_token: refresh_token,
      })
    )
  }
}
export const fetchCustomerList = (payload, firstTime = true) => {
  return async dispatch => {
    dispatch(setLoadingCustomer(true))
    let { page, limit, search, search_invoice, access_token } = payload;
    let queryString = `?page=${page}&limit=${limit}`
    if (search) {
      queryString += `&search=${search}`
    }
    if (search_invoice) {
      queryString += `&search_invoice=${search_invoice}`
    }
    return await APICall({
      access_token: access_token,
      url: `customers${queryString}`,
      verb: 'get',
    }).then(res => {
      dispatch({
        type: SET_CUSTOMER_LIST_DATA,
        payload: {
          data: res.data,
          totalPages: res.pages,
        }
      }) 
      return true;
    }).catch(error => {
      if (firstTime && error.error === "Invalid authentication credentials") {
        renewToken(fetchCustomerList, payload, dispatch)
      } else {
        dispatch(setErrorCustomer(error))
      }
    })
  }
}

