import { makeStyles } from "@material-ui/core/styles";
import settings from '../../../template/settings';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    selectGroup: {
      display: 'flex',
      width: 'calc(100% - 20px)',
      margin: '0px 10px',
      '& .MuiOutlinedInput-root': {
        color: settings.color1,
        height: 34,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${settings.color2} !important`,
      },
      '& .select > svg': {
        fill: settings.color1,
        fontSize: 20,
        marginTop: 2
      }
    },
    invalid: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid red !important',
      },
      '& .select > svg': {
        fill: 'red !important'
      },
      '& .select ': {
        color: 'red'
      }
    },
  }));
  return styles();
}
export default theme;

