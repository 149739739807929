import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import translate from '../../../misc/applyTranslation';
const OutboundInvoice = (props) => {
  const classes = theme();
  const {
    onClick,
    data
  } = props;
  return (
    <React.Fragment>

      <TableContainer component={Paper} className={classes.container} >
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align='center'>{translate('customers/detail','customer_connection_add_header')}</TableCell>
              <TableCell>{translate('customers/detail','customer_connection_supplier_header')}</TableCell>
              <TableCell>{translate('customers/detail','customer_connection_org_nr_header')}</TableCell>
              <TableCell>{translate('customers/detail','customer_connection_pg_bg_account_header')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id} onClick={() => onClick(row.id)}>
                <TableCell align='center'><AddCircleOutlineIcon /></TableCell>
                <TableCell>{row.suppl_name}</TableCell>
                <TableCell>{row.suppl_org}</TableCell>
                <TableCell>{(row.suppl_bg ? row.suppl_bg : row.suppl_pg)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
OutboundInvoice.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
}

export default memo(OutboundInvoice);