import React, { memo } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const BreadCrumbsComponent = (props) => {
  const classes = theme();
  const {
    loadingCustomer,
    loadingInvoices,
    loadingLogin,
    loadingSuppliers,
    loadingSuppliersInvoices,
    loadingFiles
  } = props;

  let loading = loadingCustomer || loadingInvoices || loadingLogin || loadingSuppliers || loadingSuppliersInvoices || loadingFiles;

  return (
    <Backdrop open={loading} className={classes.backdrop} >
      <CircularProgress className={classes.circle} />
    </Backdrop>
  );
}
BreadCrumbsComponent.propTypes = {
  loadingCustomer: PropTypes.bool.isRequired,
  loadingInvoices: PropTypes.bool.isRequired,
  loadingLogin: PropTypes.bool.isRequired,
  loadingSuppliers: PropTypes.bool.isRequired,
  loadingSuppliersInvoices: PropTypes.bool.isRequired,
  loadingFiles: PropTypes.bool.isRequired,
}
const mapStateToProps = store => ({
  loadingCustomer: store.customer.loading,
  loadingInvoices: store.invoices.loading,
  loadingLogin: store.login.loading,
  loadingSuppliers: store.suppliers.loading,
  loadingSuppliersInvoices: store.supplierInvoices.loading,
  loadingFiles: store.files.loadingFiles,
});

export default connect(mapStateToProps, null)(memo(BreadCrumbsComponent));