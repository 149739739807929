import moment from 'moment';

const yearsObj = () => {
  let returnArr = []
  let presentYear = moment().year();
  let year = 2016
  do {
    returnArr.push(
      {
        id: String(year),
        text: `Year ${year}`
      },
    )
    year++;
  } while (presentYear + 1 > year);
  return returnArr.reverse();
};


export default yearsObj();
