import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    paperPadding: {
      marginTop: 15,
      padding: 15,
      '& .pagContainer': {
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'calc(100% + 20px)',
        marginLeft: -10
      },
      '& .paper': {
        padding: 20,
        '& .title': {
          color: '#989898',
          fontSize: 20,
          fontWeight: 400,
          marginBottom: 10
        },
        '& .date': {
          marginBottom: 10,
          maxWidth: 250,
        },
        '& .button': {
          maxWidth: 250,

        },

      }
    },

  }));
  return styles();
}
export default theme;

