import { combineReducers } from 'redux';
import statesReducer from './states';
import settingsReducer from './settings';
import customerReducer from './customer';
import invoicesReducer from './invoices';
import loginReducer from './login';
import suppliersReducer from './suppliers';
import homeReducer from './home';
import supplierInvoicesReducer from './supplierInvoices';
import filesReducer from './files';

const rootReducer = combineReducers({
  state: statesReducer,
  settings: settingsReducer,
  customer: customerReducer,
  invoices: invoicesReducer,
  login: loginReducer,
  suppliers: suppliersReducer,
  home: homeReducer,
  supplierInvoices: supplierInvoicesReducer,
  files: filesReducer,
})


export default rootReducer;