import { makeStyles } from "@material-ui/core/styles";


const theme = (props) => {
  const styles = makeStyles(theme => ({
    container: {
      display: 'inline-flex',
      margin: '5px 0px'
    },
  }
  ));
  return styles();
}
export default theme;

