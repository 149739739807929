import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
const initialState = {};
const middleware = [thunk];
const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['settings', 'state', 'login'],
  blacklist: ['customer', 'invoices']
}
const pReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(
  pReducer,
  initialState,
  composeWithDevTools(
    compose(
      applyMiddleware(...middleware)
    )),
);
export const persistor = persistStore(store);