import React, { memo } from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import translate from '../../misc/applyTranslation'
const CustomerPanel = (props) => {
  const classes = theme();
  const {
    data,
    types,
    fees,
    deliveryTypes
  } = props;

  const getDeliveryName = () => {

    if(data.delivery && data.delivery != 0) {

      let delivery = deliveryTypes.find(t => t.delivery_id === data.delivery);

      return delivery.delivery;
    }else{
      return "";
    }

  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}><div className={classes.sysidlabel}>{translate('customers/detail', 'customer_overview_sys_id_label')} {data.id}</div></Grid>
      <Grid item xs={8}><div className={classes.customerNameLabel}>{translate('customers/detail', 'customer_overview_customer_label')} <span>{data.name} {data.name2}</span></div></Grid>
      <Grid item xs={4}><div className={classes.customerNrLabel}>{translate('customers/detail', 'customer_overview_customer_number_label')} {data.customer_number}</div></Grid>
      <Grid item xs={3}><p>{translate('customers/detail', 'customer_overview_address_label')}</p></Grid>
      <Grid item xs={9}>
        <div className={classes.addressDataPanel}>
          <p>{data.name} {data.name2}</p>
          <p>{data.address1} {data.address2}</p>
          <p>{data.post_code} {data.city}</p>
          <p>{data.country}</p>
        </div>
      </Grid>
      <Grid item xs={12}><div className={classes.titlesMargin}>{translate('customers/detail', 'customer_overview_personal_number_label')}{data.ssn}</div></Grid>
      <Grid item xs={3}><div className={classes.titlesMargin}>{translate('customers/detail', 'customer_overview_customer_status_label')}</div></Grid>
      <Grid item xs={9}><div className={`${classes.uppercaseValue} ${classes.titlesMargin}`}>{data.status}</div></Grid>
      <Grid item xs={3}>{translate('customers/detail', 'customer_overview_e_invoice_label')}</Grid>
      <Grid item xs={9}><div className={classes.normalizedValue}>{data.einvoice === '0' ? 'No' : 'Yes'}</div></Grid>
      <Grid item xs={3}>{translate('customers/detail', 'customer_overview_autogiro_label')}</Grid>
      <Grid item xs={9}><div className={classes.normalizedValue}>{data.auto_giro === '0' ? 'No' : 'Yes'}</div></Grid>
      <Grid item xs={3}>{translate('customers/detail', 'customer_overview_delivery_label')}</Grid>
      <Grid item xs={9}><div className={classes.normalizedValue}>{getDeliveryName()}</div></Grid>
      <Grid item xs={3}>{translate('customers/detail', 'customer_overview_customer_type_label')}</Grid>
      <Grid item xs={9}><div className={classes.normalizedValue}>{
        types && types.length > 0 && data.customer_type_id && types.find(item => item.id === data.customer_type_id).text
      }</div></Grid>
      {/* //TODO: fix this with customer types */}

      <Grid item xs={3}><div className={`${classes.uppercaseTitle} ${classes.titlesMargin}`}>{translate('customers/detail', 'customer_overview_pin_code_label')}</div></Grid>
      <Grid item xs={9}><div className={classes.titlesMargin}>{data.pin_code}</div></Grid>

      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}><div className={classes.titlesMargin}>{translate('customers/detail', 'customer_overview_delivInformationct_information_label')}</div></Grid>
          <Grid item xs={4}>{translate('customers/detail', 'customer_overview_email_label')}</Grid>
          <Grid item xs={8}><div className={classes.normalizedValue}>{data.email1}</div></Grid>
          <Grid item xs={4}>{translate('customers/detail', 'customer_overview_mobile_label')}</Grid>
          <Grid item xs={8}><div className={classes.normalizedValue}>{data.tel1}</div></Grid>
          <Grid item xs={4}>{translate('customers/detail', 'customer_overview_homePhone_label')}</Grid>
          <Grid item xs={8}><div className={classes.normalizedValue}>{data.tel2}</div></Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}><div className={classes.titlesMargin}>{translate('customers/detail', 'customer_overview_login_personal_numbers_label')}</div></Grid>
          <Grid item xs={12}>- {data.ssn}</Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}><div className={classes.titlesMargin}>{translate('customers/detail', 'customer_overview_suppliers_label')}</div></Grid>
      {fees.map((item, ind) => {
        if (item.connection_status === '1') {
          return <Grid key={ind} item xs={12}><div className={classes.normalizedValue}>{item.suppl_name}</div></Grid>
        } else { return false }
      })}
    </Grid>
  );

}
CustomerPanel.propTypes = {
  data: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
}

export default memo(CustomerPanel);
