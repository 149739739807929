
import React, { memo, useEffect } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { SuppliersList as Table } from '../../components/tables'
import Pagination from '../../components/Pagination'
import { Grid } from '@material-ui/core';
import { fetchSuppliersList, setSuppliersFilter } from '../../redux/actions/suppliers';
const CustomerListView = (props) => {
  const classes = theme();
  const {
    fetchSuppliersList,
    setSuppliersFilter,
    access_token,
    refresh_token,
    page,
    limit,
    data,
    pageTotal
  } = props;

  useEffect(() => {
    fetchSuppliersList({
      access_token: access_token,
      refresh_token: refresh_token,
      page: page,
      limit: limit,
    })
    // eslint-disable-next-line
  }, [page])
  return (
    <React.Fragment>
      <Table data={data} />
      <Grid container className={classes.fixInputMargins} >
        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Pagination page={page} totalPages={pageTotal} onClick={(e) => setSuppliersFilter({ value: e, key: 'page' })} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
CustomerListView.propTypes = {
  fetchSuppliersList: PropTypes.func.isRequired,
  setSuppliersFilter: PropTypes.func.isRequired,
  access_token: PropTypes.string.isRequired,
  refresh_token: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  pageTotal: PropTypes.number.isRequired,
}
const mapStateToProps = store => ({
  access_token: store.login.access_token,
  refresh_token: store.login.refresh_token,
  page: store.suppliers.suppliersFilter.page,
  limit: store.suppliers.suppliersFilter.limit,
  data: store.suppliers.suppliersList.data,
  pageTotal: store.suppliers.suppliersList.pageTotal
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchSuppliersList,
    setSuppliersFilter,
  }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(CustomerListView));